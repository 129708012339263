<template>
  <div id="diagram-fire" class="part-component part-diagram">
    <div class="fire-diagram">
      <div
        class="fire-layer absolute parallax-item"
        v-for="(layer, i) in layers"
        :key="i"
        :class="[layer.id, layer.class, `z-index-${layer.zIndex}`]"
        :data-depth="layer.depth"
      >
        <img :src="layer.src" />
      </div>

      <div class="svg-flames svg-layer parallax-item" data-depth="2.5">
        <svg
          version="1.1"
          id="flames"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 288.5 261.2"
          style="enable-background: new 0 0 288.5 261.2"
          xml:space="preserve"
        >
          <g>
            <g>
              <g>
                <path
                  id="XMLID_35_"
                  d="M236.3,240.8c2.2,0.3,4.4,0.1,6.5-0.7c2.1-0.7,4-1.9,5.6-3.4c3.2-3,5.2-7.1,6.6-11.2
				c0.7-2.1,1.3-4.2,1.8-6.4c0.5-2.2,1-4.3,1.4-6.5c0.8-4.4,1.5-8.8,2.2-13.1c0.4-2.2,0.7-4.4,1.2-6.6c0.4-2.2,0.9-4.4,1.5-6.5
				c0.6-2.2,1.2-4.3,2-6.4c0.8-2.1,1.7-4.2,2.7-6.2c1-2,2.2-3.9,3.5-5.8c1.3-1.8,2.7-3.6,4.3-5.2c0.8-0.8,1.6-1.6,2.4-2.3
				c0.4-0.4,0.9-0.7,1.3-1.1c0.4-0.4,0.9-0.7,1.4-1c1.8-1.3,3.8-2.3,5.9-3.3l0.2,0.7c-4,0.3-7.9,1.4-11.4,3.3
				c-3.5,1.9-6.7,4.4-9.5,7.3c-2.8,2.9-5.2,6.1-7.2,9.6c-2.1,3.4-3.7,7.2-6.1,10.5c-2.4,3.3-5.9,6-10.1,6.7c-1,0.2-2.1,0.2-3.1,0.1
				c-0.3,0-0.5-0.1-0.8-0.1l-0.4-0.1l-0.1,0l-0.1,0l-0.2-0.1l-0.7-0.2l-0.7-0.3c-1.9-0.9-3.4-2.5-4.5-4.4c-1-1.8-1.6-3.9-1.8-6
				c-0.1-1,0-2.1,0.1-3.1c0.1-1,0.4-2.1,0.7-3c1.2-4,3.4-7.5,5.3-11c2-3.5,3.8-7.1,5.4-10.7c1.6-3.7,3.1-7.4,4.3-11.2
				c2.5-7.6,4.2-15.5,5.1-23.5c0.4-4,0.6-8,0.5-12c-0.1-4-0.6-8-1.5-11.9c-0.9-3.9-1.9-7.8-2.5-11.9c-0.6-4-0.6-8.2,0.5-12.2
				l0.8,0.3c-0.5,0.9-1,1.8-1.5,2.8c-0.5,0.9-0.9,1.9-1.4,2.9c-0.4,1-0.8,1.9-1.2,2.9c-0.4,1-0.7,2-1,3c-0.6,2-1.2,4.1-1.6,6.1
				c-0.2,1-0.4,2.1-0.5,3.1c-0.1,1.1-0.2,2.1-0.3,3.2l-0.1,0c0.1-1.1,0.1-2.1,0.2-3.2c0.1-1.1,0.3-2.1,0.4-3.2
				c0.3-2.1,0.8-4.2,1.4-6.2c0.3-1,0.6-2,1-3.1c0.4-1,0.7-2,1.1-3c0.4-1,0.9-2,1.3-2.9c0.5-1,1-1.9,1.5-2.8l1.7-3l-0.9,3.3
				c-1,3.8-0.9,7.9-0.3,11.8c0.6,3.9,1.7,7.8,2.6,11.8c0.9,3.9,1.5,8,1.7,12.1c0.2,4.1,0,8.1-0.5,12.2c-0.9,8.1-2.6,16.1-5.1,23.8
				c-2.6,7.7-5.9,15.1-10,22.2c-2,3.5-4.1,6.9-5.3,10.7c-0.3,1-0.5,1.9-0.7,2.9c-0.2,1-0.2,1.9-0.2,2.9c0.1,1.9,0.6,3.9,1.6,5.5
				c0.9,1.7,2.3,3.2,4,4c1.7,0.9,3.7,1.1,5.6,0.9c1.9-0.3,3.8-1.1,5.4-2.2c1.7-1.1,3.1-2.5,4.3-4.1c2.5-3.2,4.1-6.9,6.2-10.3
				c2.1-3.5,4.6-6.7,7.4-9.6c2.8-2.9,6-5.5,9.6-7.4c3.6-1.9,7.5-3.2,11.6-3.6l1.9-0.2l-1.7,0.8c-2,1-3.9,2-5.7,3.3
				c-0.5,0.3-0.9,0.6-1.3,1c-0.4,0.4-0.9,0.7-1.3,1.1c-0.8,0.8-1.6,1.5-2.3,2.3c-1.5,1.6-2.8,3.4-4.1,5.2c-1.2,1.8-2.3,3.7-3.3,5.7
				c-1,2-1.9,4-2.6,6.1c-0.8,2.1-1.4,4.2-2,6.3c-1.1,4.3-2,8.6-2.7,13c-0.8,4.4-1.4,8.8-2.3,13.2c-0.4,2.2-0.9,4.4-1.5,6.5
				c-0.6,2.2-1.2,4.3-2,6.4c-0.8,2.1-1.7,4.2-2.8,6.1c-1.1,1.9-2.6,3.7-4.3,5.2c-1.7,1.5-3.7,2.6-5.8,3.3c-2.1,0.7-4.4,0.9-6.6,0.5
				L236.3,240.8z"
                />
              </g>
              <g>
                <path
                  id="XMLID_33_"
                  d="M64.1,241.1c-2.4-0.5-4.8-1.5-6.9-3c-2.1-1.4-3.9-3.2-5.4-5.3c-1.5-2-2.6-4.4-3.3-6.8
				c-0.7-2.4-0.9-5-1.1-7.5c-0.2-2.5-0.4-5-1.1-7.3c-0.7-2.3-1.7-4.6-3-6.7c-1.3-2.1-2.9-4-4.7-5.6c-1.8-1.6-3.9-3.1-5.7-4.9
				c-0.5-0.5-0.9-0.9-1.3-1.4c-0.4-0.5-0.8-0.9-1.2-1.5c-0.8-1.1-1.4-2.2-2-3.3c-1.1-2.3-1.8-4.8-2.3-7.3c-0.9-5-0.8-10.1-0.6-15.1
				c0.2-5,0.3-10-0.5-14.8c-0.4-2.4-1.1-4.8-2.1-7.1c-1-2.2-2.4-4.3-4.2-6l0.3-0.4c1.1,0.9,2,1.8,2.9,2.9c0.9,1.1,1.6,2.2,2.3,3.4
				c1.3,2.4,2.3,5,3,7.6c1.5,5.2,2.2,10.7,3.8,15.8c0.4,1.3,0.9,2.6,1.4,3.8c0.5,1.2,1.1,2.4,1.8,3.6c0.7,1.2,1.5,2.2,2.4,3.2
				c0.9,1,1.9,1.8,3,2.6c1.1,0.7,2.3,1.3,3.5,1.8c1.2,0.5,2.5,0.8,3.8,1c1.3,0.1,2.6,0.1,3.9-0.1c1.3-0.3,2.5-0.7,3.6-1.4l-0.1,0.8
				c-1.1-1.3-2.1-2.6-3.1-4c-1-1.3-2-2.7-3-4.1c-1-1.4-1.8-2.8-2.7-4.3c-0.8-1.5-1.6-3-2.2-4.6l-0.5-1.2l-0.4-1.2
				c-0.3-0.8-0.5-1.6-0.7-2.5c-0.2-0.8-0.4-1.7-0.5-2.5c-0.1-0.9-0.3-1.7-0.3-2.6c0-0.9-0.1-1.7-0.1-2.6l0-1.3l0.1-1.3
				c0.2-1.7,0.5-3.4,1-5.1c0.9-3.3,2.3-6.4,3.6-9.5c0.7-1.5,1.4-3.1,2-4.6c0.6-1.5,1.2-3.1,1.7-4.6c1-3.1,1.6-6.4,1.5-9.6
				c-0.1-3.2-1-6.4-2.8-9l-2.7-3.7l3.6,2.7c4.6,3.4,8.2,8,10.3,13.3c0.5,1.3,0.9,2.7,1.3,4.1c0.3,1.3,0.5,2.8,0.7,4.2
				c0.3,2.8,0.4,5.6,0.5,8.3c0.1,2.8,0.4,5.5,1,8.2c0.3,1.4,0.7,2.6,1.2,3.9c0.5,1.3,1,2.5,1.7,3.8c2.6,4.9,6.4,9.1,10.9,12.2
				l-0.1,0.1c-4.7-3-8.7-7.1-11.4-12c-0.7-1.2-1.3-2.5-1.8-3.8c-0.5-1.3-1-2.7-1.3-4c-0.7-2.7-1-5.5-1.2-8.3
				c-0.2-2.8-0.3-5.5-0.7-8.3c-0.2-1.4-0.4-2.7-0.7-4c-0.3-1.3-0.7-2.6-1.3-3.9c-2.1-5-5.6-9.4-10-12.6l1-1c2.1,2.9,3.1,6.4,3.1,9.8
				c0.1,3.5-0.6,6.9-1.6,10.1c-0.5,1.6-1.1,3.2-1.8,4.8c-0.6,1.6-1.3,3.1-2,4.6c-1.3,3.1-2.7,6.1-3.6,9.3c-0.5,1.6-0.8,3.2-1,4.8
				l-0.1,1.3l0,1.2c0,0.8,0,1.6,0,2.4c0,0.8,0.2,1.6,0.3,2.4c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.7,2.4l0.4,1.2l0.5,1.1
				c0.6,1.5,1.3,3,2.1,4.5c0.8,1.4,1.6,2.9,2.6,4.3c0.9,1.4,1.9,2.7,2.9,4.1c1,1.3,2,2.7,3,4l0.4,0.5l-0.5,0.3
				c-1.2,0.7-2.6,1.2-4,1.4c-1.4,0.2-2.8,0.2-4.2,0c-1.4-0.2-2.7-0.6-4-1.1c-1.3-0.5-2.5-1.2-3.6-2c-1.1-0.8-2.2-1.7-3.1-2.7
				c-0.9-1-1.8-2.1-2.4-3.3c-0.7-1.2-1.3-2.4-1.8-3.7c-0.5-1.3-1-2.6-1.3-3.9c-1.5-5.2-2.2-10.7-3.7-15.9c-0.7-2.6-1.7-5.1-3-7.5
				c-0.7-1.2-1.4-2.3-2.3-3.3c-0.9-1-1.8-2-2.9-2.8l0.3-0.4c1.8,1.8,3.3,3.9,4.4,6.2c1.1,2.3,1.8,4.7,2.3,7.2c0.9,5,0.9,10,0.8,15
				c-0.1,5-0.2,10,0.7,14.8c0.4,2.4,1.1,4.8,2.2,7c0.5,1.1,1.1,2.1,1.8,3.1c0.3,0.5,0.8,1,1.1,1.5c0.4,0.5,0.8,0.9,1.2,1.3
				c1.8,1.7,3.8,3.2,5.7,4.9c1.9,1.7,3.5,3.7,4.9,5.9c1.3,2.2,2.4,4.5,3.1,7c0.7,2.5,0.9,5,1,7.5c0.2,2.5,0.3,5,0.9,7.3
				c0.6,2.4,1.7,4.6,3,6.7c1.4,2,3.1,3.8,5.1,5.3c2,1.5,4.3,2.5,6.7,3.2L64.1,241.1z"
                />
              </g>
              <g>
                <path
                  id="XMLID_31_"
                  d="M241.6,95.8l-1.3-2.1l-1.1-2.2l-0.9-2.3l-0.8-2.4l-0.6-2.4l-0.4-2.5l-0.2-2.5l0-2.5l0.1-2.5
				c0.1-0.8,0.2-1.6,0.3-2.5l0.5-2.4l0.7-2.4l0.9-2.3l1-2.3l1.2-2.2l1.3-2.1l0.8,0.9c-2.9,1.7-5.4,4.1-7.1,6.9c-1.8,2.8-3,6-3.7,9.4
				c-0.3,1.7-0.5,3.4-0.6,5.1c-0.1,1.7,0,3.4,0.1,5.1c0.1,1.7,0.3,3.5,0.1,5.2c-0.1,1.7-0.6,3.5-1.6,5c-1,1.4-2.6,2.5-4.2,3.1
				c-1.7,0.6-3.5,0.8-5.2,0.6c-0.9-0.1-1.7-0.3-2.6-0.6c-0.8-0.3-1.7-0.7-2.4-1.1c-1.5-1-2.8-2.2-3.7-3.7c-1-1.5-1.6-3.2-2-4.9
				c-0.2-0.9-0.3-1.7-0.3-2.7c0-0.8,0-1.8,0.1-2.6c0.4-3.5,1.8-6.8,3.5-9.9c1.6-3.1,3.6-6,5.6-8.8c2-2.8,4-5.6,5.8-8.5
				c1.9-2.9,3.6-5.8,4.8-9c0.7-1.6,1.1-3.2,1.5-4.8c0.2-0.7,0.3-1.7,0.4-2.5c0.1-0.4,0.1-0.8,0.1-1.3l0.1-1.2
				c0.1-3.3-0.5-6.7-1.6-9.9c-1.1-3.2-2.8-6.2-4.7-8.9c-2-2.7-4.4-5.2-7.1-7.3c-2.7-2.1-5.6-3.9-8.8-5.3l0.1-0.1
				c6.5,2.4,12.3,6.6,16.6,12.1c2.2,2.7,3.9,5.8,5.1,9.1c1.2,3.3,1.9,6.8,1.9,10.4c-0.1,0.9,0,1.8-0.2,2.7c-0.1,0.9-0.2,1.7-0.4,2.6
				c-0.4,1.7-0.9,3.4-1.6,5c-1.3,3.3-3,6.3-4.9,9.2c-1.9,2.9-4,5.7-5.9,8.5c-2,2.8-3.9,5.6-5.6,8.6c-1.7,3-3,6.1-3.5,9.4
				c-0.1,0.8-0.2,1.6-0.2,2.5c0,0.8,0.1,1.7,0.2,2.5c0.3,1.6,0.9,3.2,1.7,4.7c0.9,1.4,2,2.7,3.4,3.6c0.7,0.5,1.4,0.8,2.2,1.2
				c0.8,0.3,1.6,0.5,2.5,0.6c1.7,0.2,3.4,0.1,5-0.5c0.8-0.3,1.6-0.6,2.2-1.1c0.4-0.2,0.7-0.5,1-0.8c0.3-0.3,0.6-0.6,0.8-1
				c1-1.4,1.4-3.1,1.5-4.7c0.1-1.7,0-3.4-0.2-5.1c-0.2-1.7-0.3-3.5-0.3-5.2c0-1.7,0.2-3.5,0.5-5.2c0.3-1.7,0.7-3.4,1.3-5.1
				c0.3-0.8,0.7-1.6,1-2.4c0.4-0.8,0.8-1.6,1.3-2.3c1.8-3,4.4-5.6,7.5-7.4l2.3-1.3l-1.5,2.2l-1.3,2l-1.2,2.1l-1,2.2l-0.9,2.3
				l-0.7,2.3l-0.5,2.4l-0.4,2.4l-0.2,2.4l0,2.4l0.1,2.4l0.3,2.4l0.5,2.4l0.6,2.3l0.8,2.3l1,2.2l1.1,2.2L241.6,95.8z"
                />
              </g>
              <g>
                <path
                  id="XMLID_29_"
                  d="M61.2,138.7l1-3.2c0.4-1,0.9-2.1,1.3-3.1c0.5-1,1-2,1.5-3c0.5-1,1.1-1.9,1.7-2.9c1.2-1.9,2.4-3.8,3.7-5.6
				c1.3-1.8,2.6-3.6,3.9-5.4c1.3-1.8,2.6-3.6,3.9-5.4c1.2-1.8,2.4-3.7,3.6-5.6c0.5-1,1.1-1.9,1.6-2.9l1.4-3l1.1-3.1l0.8-3.2
				c0.2-1.1,0.3-2.2,0.4-3.3c0.1-1.1,0.1-2.2,0-3.3c-0.1-2.2-0.4-4.4-0.9-6.6c-0.4-2.2-0.9-4.3-1.4-6.5c-0.5-2.2-0.9-4.4-1.1-6.6
				c-0.2-2.2-0.2-4.5,0.1-6.7c0.3-2.2,1-4.4,2.1-6.4c1.1-2,2.7-3.7,4.5-5.1c1.9-1.3,4.1-2.2,6.5-2.3l4.2-0.3L97,46.8
				c-1.4,0.5-2.7,1.4-3.6,2.6c-1,1.2-1.6,2.6-2,4c-0.4,1.5-0.5,3-0.3,4.5c0,0.3,0.1,0.8,0.2,1.1c0.1,0.4,0.2,0.7,0.3,1.1
				c0.2,0.7,0.5,1.5,0.8,2.2c1.3,2.8,3.2,5.4,5.3,7.9c2,2.5,4.2,5,6,7.7c0.9,1.4,1.7,2.9,2.2,4.4c0.6,1.6,1,3.2,1.1,4.9
				c0.4,3.3,0.1,6.6-0.3,9.8c-0.5,3.2-0.9,6.4-0.7,9.6c0.1,1.6,0.4,3.2,1,4.6c0.6,1.5,1.6,2.8,2.9,3.7l-0.1,0.1
				c-1.4-0.9-2.5-2.2-3.2-3.7c-0.7-1.5-1.1-3.1-1.3-4.7c-0.4-3.3,0-6.6,0.3-9.7c0.3-3.2,0.5-6.4,0.1-9.5c-0.2-1.6-0.6-3.1-1.2-4.5
				c-0.6-1.5-1.3-2.8-2.2-4.2c-1.7-2.7-3.8-5.1-5.9-7.6c-2.1-2.5-4.1-5.1-5.5-8.2c-0.3-0.8-0.6-1.5-0.9-2.4
				c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-1.7-0.1-3.4,0.4-5.1c0.4-1.6,1.2-3.2,2.3-4.5c1.1-1.3,2.6-2.3,4.2-2.9
				l0.2,1.2c-2.1,0.1-4.2,0.8-5.9,2c-1.8,1.2-3.3,2.8-4.4,4.6c-1.2,1.8-1.8,3.9-2.2,6.1c-0.4,2.2-0.4,4.4-0.2,6.6
				c0.2,2.2,0.6,4.4,1,6.6c0.5,2.2,1,4.3,1.5,6.5c0.5,2.2,0.9,4.4,1.1,6.6c0.1,1.1,0.1,2.3,0.1,3.4c-0.1,1.1-0.2,2.3-0.3,3.4
				l-0.7,3.3l-1.1,3.2l-1.4,3.1c-0.5,1-1.1,2-1.6,3c-0.6,1-1.2,1.9-1.7,2.9c-0.6,0.9-1.3,1.8-1.9,2.8c-1.3,1.8-2.6,3.6-4,5.4l-4,5.3
				c-1.3,1.8-2.6,3.6-3.8,5.4c-1.2,1.8-2.3,3.8-3.4,5.7c-0.5,1-1,2-1.4,3l-1.2,3.1L61.2,138.7z"
                />
              </g>
              <g>
                <path
                  id="XMLID_27_"
                  d="M208.1,4.7c0,0,0.3,0.2,0.8,0.6c0.5,0.4,1.2,1.1,2,2.1c0.8,1,1.6,2.3,2.2,4c0.7,1.6,1.2,3.6,1.3,5.8
				c0.2,2.2,0,4.5-0.5,7c-0.3,1.2-0.7,2.4-1.1,3.6c-0.5,1.2-1.1,2.4-1.7,3.6c-1.3,2.4-2.8,4.6-4.5,6.9c-1.6,2.3-3.3,4.5-5,6.8
				c-1.7,2.3-3.3,4.6-4.9,6.8c-1.6,2.3-3.1,4.5-4.5,6.8c-1.4,2.2-2.6,4.4-3.7,6.6c-0.6,1.1-1,2.1-1.6,3.2c-0.5,1-1.1,2-1.6,2.9
				c-1.1,1.8-2,3.5-2.6,5.1c-0.6,1.6-0.7,3-0.8,4.2c0,1.2,0,2.2,0.1,2.8c0.1,0.7,0.1,1,0.1,1l-0.1,0c0,0-0.1-0.3-0.2-1
				c-0.1-0.7-0.3-1.6-0.5-2.9c-0.1-1.2-0.1-2.8,0.4-4.6c0.5-1.7,1.4-3.5,2.3-5.4c1-1.9,1.9-3.9,3-6.1c1.1-2.2,2.5-4.4,3.9-6.6
				c1.5-2.2,3.1-4.5,4.8-6.7c1.7-2.2,3.5-4.4,5.3-6.6c0.9-1.1,1.8-2.2,2.6-3.3c0.9-1.1,1.7-2.2,2.5-3.4c1.6-2.3,2.9-4.7,4-7
				c0.5-1.2,1-2.4,1.4-3.6c0.4-1.2,0.8-2.4,1-3.5c0.5-2.3,0.7-4.6,0.6-6.6c-0.1-2.1-0.5-3.9-1-5.5c-0.5-1.6-1.2-2.9-1.9-3.9
				c-0.6-1-1.3-1.8-1.7-2.2c-0.4-0.5-0.7-0.7-0.7-0.7L208.1,4.7z"
                />
              </g>
              <g>
                <path
                  id="XMLID_25_"
                  d="M93.9,114.7c2.8,1.6,5.9,2.8,9.1,3.2c3.2,0.5,6.5,0.3,9.6-0.2c3.2-0.6,6.2-1.7,9.1-3.2
				c2.9-1.5,5.5-3.3,8-5.4c2.5-2.1,4.7-4.4,6.8-6.9c2.1-2.5,3.9-5.1,5.7-7.8c1.8-2.7,3.3-5.5,4.5-8.5c1.2-3,2.2-6.1,2.8-9.3
				c1.3-6.3,1.6-12.9,1.4-19.4c-0.2-6.5-0.8-13-1.4-19.5c-0.3-3.3-0.5-6.5-0.5-9.8c0-3.3,0.2-6.6,0.8-9.8c0.6-3.2,1.7-6.4,3.3-9.3
				c1.6-2.9,3.8-5.5,6.6-7.4l2-1.3l-1.3,2c-1.2,1.9-2,4.1-2.4,6.4c-0.4,2.3-0.4,4.6-0.1,6.9c0.5,4.6,2,9.1,3.8,13.4
				c0.9,2.1,2,4.3,3,6.3c1.1,2.1,2.2,4.2,3.2,6.3c2.2,4.2,4.4,8.4,6.4,12.7c1,2.1,2,4.3,2.8,6.5c0.9,2.2,1.7,4.5,2.3,6.7
				c0.7,2.3,1.2,4.6,1.7,6.9c0.5,2.3,0.9,4.7,1.2,7c0.2,2.4,0.3,4.8,0,7.2c-0.3,2.4-0.8,4.7-1.5,7c-1.5,4.5-3.5,8.8-5.4,13.1
				l-0.1-0.1c0.9-2.2,1.7-4.4,2.5-6.6c0.8-2.2,1.6-4.4,2.2-6.7c0.7-2.2,1.1-4.5,1.3-6.9c0.2-2.3,0.1-4.6-0.2-7
				c-0.3-2.3-0.7-4.6-1.3-6.9c-0.5-2.3-1.1-4.6-1.8-6.8c-0.7-2.2-1.5-4.4-2.3-6.6c-0.9-2.2-1.8-4.3-2.8-6.4c-2-4.3-4.2-8.4-6.4-12.6
				c-1.1-2.1-2.2-4.2-3.2-6.3c-1-2.1-2.1-4.2-3-6.4c-1.8-4.4-3.3-9-3.8-13.8c-0.2-2.4-0.2-4.8,0.3-7.2c0.4-2.4,1.3-4.7,2.7-6.7
				l0.7,0.7c-2.7,1.7-4.8,4.2-6.4,6.9c-1.6,2.8-2.7,5.9-3.4,9c-1.4,6.4-1,12.9-0.5,19.4c0.5,6.5,1.3,13,1.5,19.5
				c0.3,6.5,0.2,13.1-1,19.6c-0.6,3.2-1.5,6.4-2.7,9.5c-1.2,3.1-2.8,6-4.5,8.8c-1.8,2.8-3.7,5.4-5.9,7.9c-2.1,2.5-4.4,4.9-7,7
				c-2.5,2.1-5.3,4-8.2,5.5c-3,1.5-6.1,2.5-9.4,3.1c-1.6,0.3-3.3,0.4-4.9,0.4c-1.6,0-3.3-0.1-4.9-0.5c-3.2-0.6-6.4-1.8-9.1-3.6
				L93.9,114.7z"
                />
              </g>
              <g>
                <path
                  id="XMLID_23_"
                  d="M217,178.7c0,0,0-0.3,0-1c0-0.6,0.1-1.6,0.2-2.8c0.1-1.2,0.3-2.6,0.7-4.3c0.3-1.6,0.8-3.5,1.5-5.4
				c0.7-1.9,1.6-4,2.7-6.1c1.1-2.1,2.5-4.2,3.9-6.3c1.5-2.1,3.1-4.2,4.7-6.3c0.8-1.1,1.6-2.2,2.4-3.3c0.8-1.1,1.5-2.2,2.3-3.4
				c1.5-2.3,2.9-4.6,4.2-6.9c1.3-2.3,2.4-4.7,3.3-7c0.9-2.3,1.6-4.7,2.1-6.9c0.5-2.2,0.9-4.4,1.2-6.4c0.2-2,0.3-3.8,0.3-5.5
				c0-0.8,0-1.6-0.1-2.3c-0.1-0.7-0.1-1.4-0.1-1.9c-0.1-1.2-0.3-2.1-0.4-2.7c-0.1-0.6-0.2-1-0.2-1l0.1,0c0,0,0.1,0.3,0.3,0.9
				c0.2,0.6,0.4,1.5,0.7,2.7c0.2,1.2,0.4,2.6,0.6,4.3c0.1,1.7,0.1,3.6,0,5.6c-0.2,2.1-0.4,4.3-1,6.6c-0.5,2.3-1.2,4.7-2.2,7.1
				c-1,2.4-2.2,4.8-3.5,7.1c-1.4,2.3-3,4.6-4.6,6.7c-0.8,1.1-1.7,2.2-2.5,3.2c-0.8,1.1-1.6,2.1-2.5,3.2c-1.6,2.1-3.1,4.3-4.4,6.5
				c-1.3,2.2-2.4,4.3-3.5,6.4c-1,2.1-1.9,4.1-2.6,5.9c-0.7,1.9-1.3,3.6-1.7,5.2c-0.4,1.6-0.7,3-1,4.1c-0.2,1.2-0.4,2.1-0.5,2.7
				c-0.1,0.6-0.2,1-0.2,1L217,178.7z"
                />
              </g>
              <g>
                <path
                  id="XMLID_21_"
                  d="M235.7,146.6l-1.4,1.4l-0.7,0.7l-0.6,0.8l-1.2,1.5l-1.1,1.6c-0.4,0.5-0.7,1.1-1.1,1.7
				c-0.3,0.6-0.7,1.1-1,1.7c-0.6,1.2-1.2,2.3-1.7,3.5c-0.2,0.6-0.5,1.2-0.7,1.8l-0.6,1.9c-0.2,0.6-0.3,1.3-0.5,1.9
				c-0.1,0.6-0.4,1.3-0.4,1.9l-0.3,1.9c-0.1,0.6-0.2,1.3-0.2,1.9c-0.2,1.3-0.2,2.6-0.3,3.9c0,0.7,0,1.3,0.1,2c0,0.7,0.1,1.3,0.2,2
				l0.2,2l0.4,1.9c0.1,0.3,0.1,0.6,0.2,1l0.3,0.9c0.2,0.6,0.4,1.3,0.6,1.9c0.6,1.2,1,2.4,1.7,3.6c1.3,2.3,3,4.4,4.9,6.1
				c2,1.7,4.3,3,6.8,3.6l1.9,0.5l-1.8,0.7c-1.6,0.6-3,1.9-3.9,3.4c-0.9,1.5-1.4,3.3-1.6,5.1c-0.1,0.9-0.1,1.8,0,2.8
				c0,0.9,0.1,1.9,0.2,2.8c0.2,1.9,0.4,3.8,0.4,5.8l0,1.4l-0.1,1.5l-0.2,1.4l-0.3,1.4l-0.4,1.4l-0.5,1.4c-0.3,0.9-0.7,1.8-1.1,2.7
				c-0.4,0.9-0.9,1.7-1.4,2.5c-0.5,0.8-1.1,1.6-1.7,2.3l-0.9,1.1l-1,1l-1.1,1l-1.1,0.9l-0.1-0.1c0.7-0.7,1.3-1.3,2-2.1l0.9-1.1
				l0.8-1.1c0.5-0.8,1-1.6,1.5-2.4c0.5-0.8,0.9-1.7,1.2-2.5c0.4-0.9,0.7-1.7,1-2.6l0.4-1.3l0.3-1.4l0.2-1.4l0.2-1.4l0.1-1.4l0-1.4
				c0-1.9-0.3-3.7-0.5-5.6c-0.1-1-0.2-1.9-0.2-2.9c0-1,0-2,0.1-3c0.2-2,0.8-4,1.9-5.7c1-1.7,2.7-3.2,4.6-3.8l0.1,1.2
				c-2.7-0.7-5.1-2.2-7-4.1c-2-1.9-3.5-4.1-4.8-6.5c-0.7-1.2-1.1-2.4-1.7-3.6c-0.3-0.6-0.4-1.3-0.7-1.9c-0.2-0.6-0.4-1.3-0.6-1.9
				l-0.5-1.9l-0.4-2c-0.1-0.7-0.2-1.3-0.3-2c-0.1-0.7-0.2-1.3-0.2-2c0-1.3-0.1-2.7,0-4c0.1-1.4,0.3-2.7,0.5-4c0.1-0.7,0.3-1.3,0.4-2
				c0.2-0.7,0.3-1.3,0.6-1.9l0.7-1.9c0.2-0.6,0.5-1.2,0.8-1.9c0.5-1.3,1.2-2.4,1.8-3.6c0.4-0.6,0.7-1.1,1.1-1.7
				c0.4-0.6,0.7-1.1,1.2-1.6l1.3-1.6c0.5-0.5,0.9-1,1.4-1.5l0.7-0.7l0.8-0.7l1.5-1.3L235.7,146.6z"
                />
              </g>
              <g>
                <path
                  id="XMLID_19_"
                  d="M177.2,58c0.3-0.9,0.7-1.7,1.2-2.5c0.5-0.8,1.2-1.5,2-2c0.7-0.5,1.5-1,2.3-1.4c0.8-0.4,1.5-0.9,2.2-1.4
				c0.7-0.5,1.4-1.1,1.8-1.8c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.4,0.2-0.8,0.2-1.2c0-1.7-0.6-3.3-1.1-5
				l-0.6-1.8l1.6,1c1.2,0.7,2.2,1.7,2.8,3c0.7,1.2,1,2.6,1,4c0,0.3,0,0.7-0.1,1c-0.1,0.3-0.1,0.7-0.2,1c-0.2,0.7-0.4,1.2-0.7,1.8
				c-0.4,1.2-0.9,2.3-0.6,3.6l-0.1,0c-0.3-0.6-0.4-1.3-0.4-1.9c0-0.7,0.1-1.3,0.3-1.9c0.1-0.6,0.3-1.3,0.4-1.8
				c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6,0-0.9c-0.1-1.2-0.4-2.3-1-3.3c-0.6-1-1.3-1.9-2.3-2.6l1-0.8c0.5,1.7,0.9,3.6,0.6,5.4
				c-0.1,0.5-0.2,0.9-0.4,1.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.4,0.6c-0.5,0.7-1.2,1.4-1.9,1.9c-0.3,0.3-0.7,0.6-1,0.9
				l-1,0.9c-0.3,0.3-0.7,0.6-1,0.9c-0.3,0.3-0.7,0.5-1.1,0.8c-0.7,0.5-1.3,1-2,1.6l-0.9,0.9c-0.3,0.3-0.6,0.7-0.9,1L177.2,58z"
                />
              </g>
            </g>
            <g>
              <path
                id="XMLID_17_"
                d="M249.3,136.3c1,0.9,1.8,2.1,2.3,3.4c0.5,1.3,0.9,2.7,1,4c0.3,2.8,0,5.6-0.5,8.3c-0.2,1.4-0.6,2.7-0.8,4
			c-0.2,1.3-0.3,2.7-0.1,4c0.3,1.3,0.9,2.5,1.9,3.2c0.2,0.2,0.5,0.3,0.8,0.4c0.4,0.1,0.6,0.1,0.9,0.1c0.6,0,1.2-0.2,1.7-0.5
			c1.1-0.6,1.9-1.7,2.5-2.9c0.5-1.2,0.6-2.5,0.6-3.9c0-1.3-0.2-2.7-0.3-4.1c-0.2-1.4-0.3-2.8-0.3-4.2c0-1.4,0.1-2.8,0.4-4.2
			c0.3-1.4,0.7-2.7,1.3-4c0.6-1.2,1.5-2.4,2.5-3.4c1-1,2.2-1.8,3.6-2.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2
			c-0.8,0.4-1.4,1.2-1.7,2.1c-0.3,0.9-0.4,1.8-0.3,2.8c0.2,0.9,0.6,1.8,1.2,2.5c0.6,0.7,1.4,1.2,2.3,1.6c0.9,0.3,1.8,0.5,2.8,0.4
			c0.9-0.1,1.8-0.4,2.7-0.8c1.7-0.8,3.2-2.1,4.3-3.6c0.5-0.8,1-1.6,1.1-2.5c0.2-0.9,0.1-1.8-0.1-2.7c-0.4-1.8-1.5-3.4-2.6-4.9
			c-2.3-3-5-5.8-7.9-8.3c-0.2-0.2-0.2-0.5-0.1-0.7c0.2-0.2,0.4-0.2,0.6-0.1c2.1,1.4,4.7,2,7.1,3.1c1.2,0.6,2.4,1.3,3.4,2.2
			c1,0.9,1.8,2,2.5,3.2c1.4,2.4,2.1,5.1,2.1,7.8c0,1.4-0.1,2.7-0.4,4c-0.3,1.3-0.7,2.6-1.3,3.8c-1.1,2.4-2.8,4.6-4.7,6.4
			c-1,0.9-2,1.7-3.1,2.5c-1.1,0.7-2.2,1.4-3.3,2.1c-2.2,1.4-4.4,2.9-6,4.9c-0.8,1-1.4,2.2-1.8,3.4c-0.4,1.2-0.5,2.6-0.3,3.8
			c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c-0.3-1.3-0.2-2.7,0.2-3.9c0.4-1.3,1-2.5,1.8-3.6c0.8-1.1,1.7-2,2.7-2.9c1-0.8,2.1-1.6,3.2-2.3
			c1.1-0.7,2.2-1.4,3.3-2.2c1.1-0.7,2.1-1.5,3-2.4c1.8-1.8,3.4-3.9,4.4-6.3c0.5-1.2,0.9-2.4,1.2-3.6c0.3-1.2,0.4-2.5,0.4-3.8
			c-0.1-2.5-0.8-5.1-2-7.3c-0.6-1.1-1.4-2.1-2.3-2.9c-0.9-0.8-2-1.5-3.1-2.1c-2.3-1.1-4.9-1.8-7.2-3.3l0.6-0.8
			c2.9,2.5,5.6,5.4,7.9,8.5c1.1,1.6,2.2,3.3,2.6,5.3c0.2,1,0.2,2,0,3c-0.2,1-0.7,1.9-1.3,2.7c-0.6,0.8-1.3,1.5-2.1,2.1
			c-0.8,0.6-1.6,1.2-2.5,1.6c-0.9,0.4-1.9,0.7-2.8,0.7c-1,0.1-2-0.1-2.9-0.4c-0.9-0.4-1.8-0.9-2.4-1.7c-0.7-0.7-1.1-1.7-1.3-2.6
			c-0.2-1-0.1-2,0.3-2.9c0.3-0.9,1-1.8,1.9-2.3l0.1,0.3c-2.6,0.9-4.6,3-5.7,5.5c-1.2,2.5-1.5,5.2-1.5,7.9c0,1.4,0.2,2.7,0.4,4.1
			c0.2,1.4,0.4,2.7,0.4,4.2c0,1.4-0.1,2.9-0.7,4.2c-0.6,1.3-1.5,2.5-2.8,3.3c-0.7,0.4-1.4,0.6-2.2,0.6c-0.4,0-0.8-0.1-1.1-0.2
			c-0.4-0.1-0.7-0.3-1-0.5c-1.3-0.9-1.9-2.3-2.2-3.7c-0.3-1.4-0.1-2.9,0.1-4.2c0.3-1.4,0.6-2.7,0.9-4c0.6-2.7,0.9-5.4,0.7-8.1
			C252.1,141.1,251.2,138.3,249.3,136.3C249.3,136.3,249.3,136.3,249.3,136.3C249.3,136.2,249.3,136.2,249.3,136.3L249.3,136.3z"
              />
            </g>
            <g>
              <path
                id="XMLID_15_"
                d="M26.9,146.5c2.4,0,4.4-1.5,5.7-3.3c0.6-0.9,1.1-2,1.3-3.1c0.2-1.1,0.3-2.2,0.1-3.3
			c-0.2-1.1-0.5-2.2-0.9-3.2c-0.5-1-1-2-1.6-3c-1.2-1.9-2.7-3.7-4.1-5.6c-1.4-1.8-2.8-3.7-4-5.8c-0.6-1-1-2.1-1.4-3.3
			c-0.4-1.1-0.5-2.3-0.6-3.5c-0.1-2.4,0.3-4.7,1.3-6.9c0.5-1.1,1.1-2.1,1.8-3.1c0.7-0.9,1.6-1.8,2.5-2.5c0.2-0.1,0.4-0.1,0.6,0.1
			c0.1,0.2,0.1,0.4,0,0.5l0,0c-0.9,1.3-1.4,2.9-1.4,4.5c0,1.6,0.2,3.3,0.6,4.9c0.4,1.6,0.9,3.2,1.7,4.6c0.2,0.4,0.4,0.7,0.6,1
			c0.2,0.3,0.5,0.6,0.8,0.9c0.6,0.6,1.1,1.1,1.8,1.6c2.6,2,5.8,3.1,8.9,4.4c1.6,0.7,3.1,1.4,4.5,2.3c0.4,0.2,0.7,0.5,1.1,0.7
			c0.3,0.3,0.7,0.5,1,0.8c0.3,0.3,0.6,0.6,0.9,0.9l0.8,1c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0l0,0l-0.9-0.9c-0.3-0.3-0.6-0.5-1-0.8
			c-0.3-0.3-0.7-0.5-1-0.7c-0.3-0.2-0.7-0.4-1.1-0.6c-0.7-0.4-1.5-0.8-2.2-1.1c-0.8-0.3-1.5-0.6-2.3-0.9c-1.6-0.6-3.2-1.2-4.7-1.8
			c-0.8-0.3-1.6-0.7-2.3-1.1c-0.4-0.2-0.8-0.4-1.1-0.6c-0.4-0.2-0.7-0.5-1.1-0.7c-0.7-0.5-1.4-1.1-2-1.8c-0.3-0.3-0.5-0.6-0.8-1
			c-0.3-0.4-0.5-0.7-0.7-1.1c-0.9-1.5-1.4-3.2-1.8-4.9c-0.4-1.7-0.6-3.4-0.6-5.1c0.1-1.7,0.6-3.6,1.7-5l0.6,0.6
			c-3.6,2.7-5.9,7.2-5.8,11.9c0,1.2,0.2,2.3,0.5,3.4c0.3,1.1,0.8,2.2,1.4,3.2c1.1,2,2.7,3.8,4.2,5.6c0.8,0.9,1.5,1.8,2.3,2.7
			c0.7,0.9,1.4,1.9,2.1,2.9c1.3,2,2.3,4.2,2.6,6.6c0.2,1.2,0.1,2.4-0.2,3.6c-0.3,1.2-0.8,2.3-1.6,3.2c-0.7,1-1.6,1.8-2.7,2.4
			C29.3,146.4,28,146.7,26.9,146.5L26.9,146.5C26.9,146.6,26.8,146.5,26.9,146.5C26.8,146.5,26.9,146.5,26.9,146.5z"
              />
            </g>
            <g>
              <path
                id="XMLID_13_"
                d="M21.4,146.7c-0.5-0.1-1-0.2-1.5-0.4c-0.5-0.2-1-0.4-1.4-0.6c-0.9-0.4-1.8-1-2.6-1.7
			c-1.6-1.3-2.9-3.1-3.6-5.1c-0.2-0.5-0.3-1-0.4-1.5c-0.1-0.5-0.2-1-0.2-1.6c0-0.5,0-1.1,0-1.6c0-0.5,0.1-1,0.2-1.6
			c0.1-0.5,0.3-1,0.4-1.5c0.2-0.5,0.4-1,0.6-1.5c0.3-0.5,0.5-0.9,0.8-1.4c0.3-0.4,0.7-0.8,1-1.2l0.4,0.6c-2,0.8-3.7,2.4-4.9,4.2
			c-1.2,1.8-2,3.9-2.6,6.1c-0.5,2.2-0.7,4.4-0.7,6.7c0,2.2,0.3,4.5,0.8,6.7c0.5,2.2,1.3,4.3,2.2,6.3c1,2,2.1,4,3.2,5.9
			c2.3,3.9,4.9,7.7,6.6,11.9c0.9,2.1,1.5,4.3,1.7,6.6c0.2,2.3-0.1,4.7-1.3,6.7c-1.1,2-3.2,3.5-5.4,4.1c-1.1,0.3-2.3,0.4-3.5,0.1
			c-1.1-0.2-2.2-0.8-3-1.7c-0.8-0.8-1.4-1.9-1.7-3.1c-0.3-1.1-0.4-2.3-0.4-3.4c0-2.3,0.4-4.6,0.3-6.8c0-2.2-0.6-4.4-1.7-6.3
			c-1.1-1.9-2.8-3.4-4.8-4.4l0.6-0.6c1.4,3.5,1.7,7.4,1.6,11.1c-0.1,3.7-0.6,7.4-0.7,11.1c-0.1,1.8,0,3.7,0.1,5.5
			c0.2,1.8,0.4,3.6,0.8,5.4c0.7,3.6,1.9,7.1,3.5,10.3c3.1,6.6,8,12.4,13.9,16.7c3,2.2,6.2,4,9.6,5.4c1.7,0.7,3.4,1.4,5.2,1.9
			c1.8,0.6,3.6,0.9,5.4,1.3c0,0,0,0,0,0.1c0,0,0,0-0.1,0c-7.3-1.1-14.4-3.9-20.5-8.2c-6.1-4.3-11.1-10.1-14.4-16.8
			c-1.6-3.4-2.9-6.9-3.6-10.6c-0.4-1.8-0.6-3.7-0.8-5.5c-0.1-1.9-0.2-3.7-0.1-5.6c0.2-3.7,0.7-7.4,0.8-11c0.1-3.6-0.1-7.3-1.5-10.7
			l0,0c-0.1-0.2,0-0.5,0.2-0.6c0.1-0.1,0.3,0,0.4,0c2.1,1.1,3.8,2.8,5,4.8c1.2,2,1.8,4.4,1.7,6.7c0,2.3-0.4,4.6-0.4,6.8
			c-0.1,2.2,0.2,4.6,1.8,6.2c1.5,1.7,4,2.1,6.1,1.5c2.1-0.6,4.1-1.9,5.2-3.9c1.1-2,1.4-4.3,1.2-6.5c-0.2-2.2-0.8-4.4-1.8-6.5
			c-1.8-4.2-4.4-7.9-6.8-11.7c-1.2-1.9-2.4-3.9-3.4-5.9c-1-2.1-1.8-4.2-2.4-6.5c-0.5-2.2-0.8-4.5-0.8-6.8c0-2.3,0.3-4.6,0.8-6.9
			c0.5-2.2,1.4-4.4,2.7-6.4c1.3-1.9,3.1-3.6,5.3-4.4c0.2-0.1,0.4,0,0.5,0.2c0.1,0.1,0,0.3-0.1,0.4c-0.3,0.4-0.7,0.7-1,1.1
			c-0.3,0.4-0.6,0.8-0.8,1.3c-0.2,0.4-0.4,0.9-0.6,1.4c-0.2,0.5-0.3,0.9-0.4,1.4c-0.1,0.5-0.2,1-0.3,1.5c0,0.5-0.1,1-0.1,1.5
			c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.2,1,0.4,1.5c0.3,1,0.8,1.9,1.3,2.7c0.6,0.8,1.2,1.6,2,2.3c0.8,0.7,1.6,1.3,2.5,1.7
			c0.5,0.2,0.9,0.4,1.4,0.6C20.4,146.4,20.9,146.5,21.4,146.7C21.5,146.6,21.5,146.7,21.4,146.7C21.5,146.7,21.4,146.7,21.4,146.7z"
              />
            </g>
            <g>
              <path
                id="XMLID_11_"
                d="M33.3,257.1c0,0-0.9-0.1-2.5-0.2c-0.8-0.2-1.7-0.3-2.8-0.6c-1.1-0.3-2.2-0.7-3.5-1.3
			c-0.3-0.1-0.6-0.3-1-0.4c-0.3-0.2-0.6-0.3-0.9-0.5c-0.6-0.4-1.3-0.7-1.9-1.2c-1.3-0.9-2.5-1.9-3.8-3.1c-1.2-1.2-2.4-2.5-3.5-3.8
			c-1.1-1.3-2.2-2.8-3.2-4.3c-0.5-0.8-1-1.5-1.4-2.3c-0.4-0.8-0.9-1.6-1.2-2.4c-0.3-0.8-0.7-1.6-1-2.5c-0.3-0.8-0.6-1.6-0.8-2.5
			c-0.2-0.8-0.4-1.6-0.6-2.4c-0.2-0.8-0.3-1.6-0.4-2.4c-0.2-1.5-0.3-3-0.3-4.4c-0.1-2.7,0-4.9,0.1-6.5c0.1-1.6,0.2-2.5,0.2-2.5
			c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0.9,0.1,2.5c0.1,1.6,0.2,3.8,0.4,6.5c0.1,1.3,0.3,2.8,0.5,4.3c0.1,0.7,0.2,1.5,0.4,2.3
			c0.2,0.8,0.4,1.6,0.5,2.4c0.2,0.8,0.5,1.6,0.7,2.4c0.2,0.8,0.6,1.6,0.9,2.4c0.7,1.6,1.4,3.2,2.3,4.8c0.4,0.8,0.9,1.5,1.4,2.3
			c0.5,0.7,1.1,1.4,1.6,2.1c0.5,0.7,1.2,1.3,1.8,1.9c0.6,0.6,1.3,1.1,1.9,1.7c1.3,1,2.6,2,3.9,2.8c0.6,0.4,1.3,0.8,1.9,1.1
			c0.3,0.2,0.6,0.4,0.9,0.5c0.3,0.1,0.6,0.3,0.9,0.4c1.2,0.6,2.3,1,3.3,1.4c1,0.4,1.9,0.6,2.7,0.8C32.4,256.8,33.3,257,33.3,257.1
			C33.3,257,33.3,257,33.3,257.1C33.3,257.1,33.3,257.1,33.3,257.1z"
              />
            </g>
            <g>
              <path
                id="XMLID_9_"
                d="M245.7,261.1c0,0,0.3-0.1,0.9-0.4c0.3-0.1,0.6-0.3,1-0.5c0.4-0.2,0.8-0.5,1.3-0.8c0.5-0.3,1-0.6,1.6-1
			c0.6-0.4,1.2-0.8,1.8-1.3c1.3-1,2.6-2.2,4-3.6c1.4-1.4,2.7-3.1,4-5c1.3-1.9,2.5-3.9,3.6-6.2c1.1-2.2,1.9-4.7,2.5-7.2
			c0.6-2.6,1-5.2,1.2-7.8c0.2-2.7,0.2-5.3,0.2-7.9c0-2.6-0.2-5.2-0.3-7.7c-0.2-2.5-0.4-4.9-0.5-7.2c-0.2-2.3-0.3-4.5-0.5-6.5
			c-0.3-4-0.5-7.3-0.6-9.7c-0.1-2.3-0.2-3.7-0.2-3.7c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0.2,1.3,0.5,3.6c0.3,2.3,0.7,5.6,1.1,9.6
			c0.2,2,0.4,4.2,0.6,6.4c0.2,2.3,0.4,4.7,0.6,7.2c0.1,2.5,0.2,5.1,0.2,7.7c0,2.6-0.2,5.3-0.5,8c-0.3,2.7-0.8,5.3-1.4,7.9
			c-0.6,2.6-1.4,5-2.3,7.4c-1,2.3-2,4.5-3.3,6.5c-1.3,1.9-2.7,3.7-4.1,5.1c-1.4,1.4-2.8,2.7-4.2,3.6c-0.7,0.5-1.3,0.9-1.9,1.2
			c-0.6,0.4-1.2,0.7-1.7,0.9c-0.5,0.3-1,0.5-1.4,0.7c-0.4,0.2-0.8,0.3-1.1,0.4C246.1,261.1,245.8,261.2,245.7,261.1
			C245.8,261.2,245.7,261.2,245.7,261.1C245.7,261.2,245.7,261.1,245.7,261.1z"
              />
            </g>
            <g>
              <path
                id="XMLID_7_"
                d="M106.1,97.8c0.9,0.7,1.9,1.2,3,1.5c1.1,0.3,2.2,0.4,3.3,0.5c1.1,0.1,2.3,0,3.4,0c1.1,0,2.3-0.1,3.4-0.2
			c1.1-0.1,2.2-0.3,3.2-0.7c0.5-0.2,1-0.4,1.5-0.7c0.2-0.1,0.5-0.3,0.7-0.4c0.2-0.2,0.5-0.3,0.6-0.5c0.8-0.7,1.4-1.6,1.8-2.6
			c0.4-1,0.7-2.1,0.8-3.2c0.1-1.1,0.1-2.2-0.1-3.4c-0.2-1.1-0.5-2.2-0.8-3.3c-0.3-1.1-0.8-2.1-1.2-3.2c-0.4-1.1-0.8-2.1-1.2-3.2
			c-0.8-2.2-1.3-4.5-1.1-6.8c0.1-1.2,0.3-2.4,0.8-3.5c0.5-1.1,1.1-2.1,2-3l1-0.9l-0.2,1.3c-0.2,1.3-0.1,2.6,0.2,3.9
			c0.3,1.2,1,2.4,1.9,3.3c0.3,0.3,0.5,0.4,0.7,0.6c0.3,0.2,0.5,0.4,0.8,0.5c0.6,0.3,1.2,0.6,1.8,0.7c0.6,0.2,1.3,0.2,1.9,0.2
			c0.6,0,1.3-0.2,1.8-0.4c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.2,0.5-0.3,0.8-0.5c0.5-0.4,1-0.8,1.5-1.3l1.4-1.5c0.5-0.5,1-1,1.6-1.4
			c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.4c0.7-0.2,1.4-0.4,2.1-0.5c0.7,0,1.4,0,2.1,0.3c0.7,0.3,1.2,0.8,1.5,1.4l-0.1,0
			c-0.3-0.6-0.9-1-1.5-1.2c-0.6-0.2-1.3-0.2-2-0.1c-0.7,0.1-1.3,0.3-1.9,0.5c-0.6,0.3-1.1,0.6-1.7,1c-0.5,0.4-1,0.9-1.4,1.4
			l-1.4,1.5c-0.5,0.5-1,1-1.5,1.4c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.7,0.4-1,0.5c-1.4,0.6-2.9,0.6-4.3,0.3
			c-0.7-0.2-1.4-0.4-2-0.8c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.6-0.5-0.8-0.7c-2.1-2.1-2.8-5.2-2.3-8l0.8,0.4
			c-1.6,1.4-2.5,3.6-2.7,5.8c-0.1,1.1-0.1,2.2,0.1,3.3c0.1,1.1,0.4,2.2,0.8,3.3c0.3,1.1,0.7,2.2,1.1,3.2c0.4,1.1,0.8,2.1,1.2,3.2
			c0.7,2.2,1.3,4.4,1.2,6.8c-0.1,2.3-0.8,4.7-2.6,6.5c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.5,0.4-0.8,0.5c-0.5,0.3-1.1,0.6-1.6,0.7
			c-1.1,0.4-2.3,0.5-3.5,0.6c-1.2,0.1-2.3,0.1-3.5,0.1c-1.1,0-2.3,0-3.5-0.2c-1.1-0.1-2.3-0.3-3.4-0.7
			C107.9,99.2,106.9,98.6,106.1,97.8L106.1,97.8z"
              />
            </g>
          </g>
        </svg>
      </div>
      <div class="svg-smoke svg-layer parallax-item" data-depth="2">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 227.4 321.4"
          style="enable-background: new 0 0 227.4 321.4"
          xml:space="preserve"
          id="smoke"
        >
          <g>
            <g>
              <path
                id="XMLID_31_"
                d="M6,320.8c0.5-0.7,0.9-1.3,1.3-2.1c0.4-0.7,0.6-1.5,0.8-2.3c0.4-1.6,0.5-3.2,0.2-4.8
			c-0.2-1.6-0.8-3.1-1.7-4.5c-0.9-1.4-2-2.5-3.1-3.8c-1.1-1.2-2.2-2.5-2.9-4.1c-0.7-1.6-0.8-3.4-0.5-5c0.3-1.7,1.1-3.2,2.1-4.6
			c1-1.3,2.3-2.5,3.8-3.3c1.4-0.8,3.1-1.4,4.7-1.7c1.6-0.3,3.3-0.4,5-0.3c1.7,0.1,3.3,0.4,4.9,0.9c0.8,0.2,1.6,0.5,2.3,0.8
			c0.8,0.3,1.5,0.8,2.1,1.4c1.1,1.3,1.4,3.1,1.4,4.8c-0.1,1.7-0.6,3.4-1.7,4.6c-0.6,0.6-1.3,1.1-2.1,1.4c-0.8,0.3-1.7,0.3-2.5,0.1
			c-0.8-0.2-1.6-0.6-2.2-1.2c-0.6-0.6-0.9-1.5-0.7-2.3c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1c-0.3,1.9,0.6,3.9,2,5.1
			c1.4,1.3,3.5,1.7,5.4,1.4c0.9-0.2,1.9-0.5,2.7-1c0.8-0.5,1.5-1.3,1.9-2.1c0.9-1.7,1-3.7,0.8-5.6c0.2,1.9,0.1,3.9-0.8,5.7
			c-0.5,0.9-1.1,1.6-1.9,2.1c-0.8,0.5-1.7,0.9-2.7,1.1c-1.9,0.4-4-0.1-5.5-1.4c-1.5-1.3-2.4-3.3-2.1-5.2l0.2,0
			c-0.2,0.8,0.1,1.6,0.7,2.2c0.6,0.6,1.3,1,2.1,1.1c0.8,0.2,1.6,0.2,2.4-0.1c0.8-0.3,1.4-0.8,2-1.4c1.1-1.2,1.5-2.9,1.6-4.5
			c0.1-1.6-0.3-3.3-1.3-4.6c-1.1-1.2-2.7-1.7-4.3-2.2c-1.6-0.5-3.2-0.8-4.8-0.8c-3.3-0.2-6.6,0.4-9.4,2c-1.4,0.8-2.6,1.9-3.6,3.2
			c-0.9,1.3-1.7,2.8-1.9,4.4c-0.3,1.6-0.2,3.2,0.5,4.7c0.6,1.5,1.7,2.7,2.8,3.9c1.1,1.2,2.3,2.4,3.2,3.8c0.9,1.4,1.6,3,1.8,4.7
			c0.3,1.7,0.2,3.4-0.2,5c-0.4,1.6-1.2,3.2-2.2,4.6l0,0c-0.1,0.2-0.3,0.2-0.5,0.1C6,321.2,5.9,321,6,320.8z"
              />
            </g>
            <g>
              <path
                id="XMLID_29_"
                d="M218.3,299.7c-0.4-0.4-0.8-1-1-1.6c-0.3-0.6-0.4-1.2-0.4-1.9c-0.1-1.3,0.2-2.5,0.7-3.7
			c0.5-1.2,1.1-2.2,1.9-3.2c0.8-1,1.6-1.8,2.5-2.7c0.8-0.9,1.7-1.7,2.4-2.7c0.8-0.9,1.4-1.9,1.9-3c0.5-1.1,0.7-2.3,0.7-3.4
			c0-1.2-0.3-2.3-0.8-3.4c-1-2.2-2.6-4-4.4-5.5c-0.9-0.7-2-1.4-3-1.9c-1.1-0.5-2.2-0.9-3.4-0.9c-1.2,0-2.3,0.3-3.3,1
			c-1,0.7-1.6,1.8-1.3,2.9c0.2,1.1,1.1,2,2.1,2.6c1,0.6,2.2,0.8,3.4,0.8l0,0c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
			c-1.6,0.1-3.3,0.1-4.9-0.4c-0.8-0.3-1.5-0.7-2-1.4c-0.5-0.7-0.8-1.6-0.6-2.4c0.3-1.7,1.4-3,2.5-4.2c1.1-1.2,2.4-2.2,3.5-3.4
			c1.1-1.2,2.2-2.4,3.1-3.7c0.9-1.3,1.6-2.8,1.3-4.4c-0.2-0.8-0.6-1.5-1.2-1.9c-0.6-0.5-1.3-0.8-2.1-1c-1.5-0.4-3.2-0.2-4.6,0.5
			c-1.4,0.7-2.6,1.9-3.6,3.1c-1,1.3-1.8,2.7-2.5,4.1c-1.4,2.9-2.4,6-2.3,9.3c0,1.6,0.3,3.2,0.9,4.7c0.6,1.5,1.4,2.9,2.5,4.1
			c1.1,1.2,2.4,2.2,3.8,2.9c1.4,0.7,3,1.1,4.6,1.2c-1.6-0.1-3.2-0.5-4.6-1.2c-1.5-0.7-2.7-1.7-3.8-2.9c-1.1-1.2-2-2.6-2.6-4.1
			c-0.6-1.5-0.9-3.1-0.9-4.7c-0.1-3.2,0.9-6.4,2.3-9.3c0.7-1.4,1.5-2.9,2.5-4.1c1-1.3,2.2-2.4,3.6-3.2c1.4-0.8,3.2-1,4.8-0.6
			c0.8,0.2,1.6,0.5,2.2,1.1c0.6,0.5,1.1,1.3,1.3,2.1c0.4,1.7-0.4,3.3-1.3,4.6c-0.9,1.3-2,2.6-3.1,3.7c-1.1,1.2-2.4,2.2-3.4,3.4
			c-1.1,1.2-2.1,2.5-2.4,4c-0.1,0.8,0.1,1.5,0.6,2.1c0.4,0.6,1.1,1,1.9,1.3c1.5,0.5,3.1,0.5,4.7,0.4l0,0.4c-1.3,0-2.5-0.2-3.6-0.8
			c-1.1-0.6-2.1-1.5-2.4-2.9c-0.1-0.3-0.1-0.7,0-1c0-0.3,0.1-0.6,0.3-0.9c0.3-0.6,0.7-1.1,1.2-1.5c1-0.8,2.3-1.1,3.6-1.1
			c1.3,0,2.5,0.4,3.6,0.9c1.1,0.5,2.2,1.2,3.1,2c1.9,1.5,3.6,3.4,4.6,5.7c0.5,1.1,0.8,2.4,0.9,3.6c0,1.3-0.2,2.5-0.7,3.7
			c-0.5,1.2-1.2,2.2-2,3.2c-0.8,1-1.6,1.8-2.5,2.7c-0.8,0.9-1.7,1.8-2.4,2.7c-0.7,0.9-1.3,2-1.8,3.1c-0.4,1.1-0.7,2.3-0.6,3.4
			c0,0.6,0.2,1.1,0.4,1.7c0.2,0.5,0.5,0.9,0.9,1.4c0.1,0.1,0.1,0.4,0,0.5C218.6,299.8,218.4,299.8,218.3,299.7L218.3,299.7z"
              />
            </g>
            <g>
              <path
                id="XMLID_27_"
                d="M29.6,293.9c0,0,0.1-0.3,0.1-0.9c0.1-0.6,0.1-1.5,0-2.6c-0.1-1.1-0.4-2.5-1.1-3.9
			c-0.3-0.7-0.7-1.4-1.2-2.2c-0.5-0.7-1.1-1.4-1.7-2.2c-0.6-0.8-1.2-1.6-1.6-2.7c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0-0.6,0.1-1.2,0.2-1.7c0.6-2.3,2.1-4.3,3.8-6.1c1.7-1.8,3.5-3.6,4.6-5.9c0.5-1.2,0.7-2.4,0.5-3.7c-0.2-1.3-0.8-2.4-1.5-3.5
			c-1.6-2.1-3.8-3.6-6.1-4.7c-1.2-0.5-2.4-1-3.6-1.2c-1.2-0.2-2.5-0.1-3.6,0.4c-1.1,0.5-2,1.4-2.3,2.5c-0.3,1.1,0.3,2.3,1.2,3
			c0.9,0.7,1.9,1.1,3,1.3c1,0.2,2.1,0.3,3,0.4c1,0.1,1.9,0.3,2.8,0.6c0.9,0.3,1.6,0.8,2.1,1.4c1.1,1.2,1.4,2.7,1.4,3.8
			c0.1,1.2-0.1,2.1-0.2,2.7c-0.1,0.6-0.2,0.9-0.2,0.9s0.1-0.3,0.2-0.9c0.1-0.6,0.2-1.5,0.2-2.7c-0.1-1.1-0.3-2.6-1.4-3.8
			c-1.1-1.2-2.9-1.7-4.9-1.9c-1-0.1-2-0.2-3.1-0.4c-1-0.2-2.1-0.6-3-1.3c-0.9-0.7-1.6-1.9-1.3-3.1c0.3-1.2,1.3-2.1,2.4-2.7
			c1.1-0.6,2.5-0.6,3.7-0.4c1.3,0.2,2.5,0.6,3.7,1.2c2.4,1.1,4.6,2.6,6.3,4.7c0.8,1.1,1.4,2.3,1.6,3.6c0.2,1.3,0,2.7-0.5,3.9
			c-1.1,2.4-3,4.3-4.6,6.1c-1.6,1.8-3.1,3.8-3.6,5.9c-0.1,0.5-0.2,1.1-0.2,1.6c0,0.3,0.1,0.5,0.1,0.8c0.1,0.2,0.1,0.6,0.2,0.7
			c0.3,1,0.9,1.8,1.5,2.5c0.6,0.8,1.3,1.5,1.8,2.2c0.5,0.7,1,1.5,1.3,2.3c0.7,1.5,1,2.9,1.2,4.1c0.1,1.2,0.1,2.1,0,2.7
			c-0.1,0.6-0.1,0.9-0.1,0.9l0,0c0,0.2-0.2,0.3-0.4,0.3C29.7,294.3,29.5,294.1,29.6,293.9L29.6,293.9z"
              />
            </g>
            <g>
              <path
                id="XMLID_25_"
                d="M207.6,253.5c0.1-1.1,0-2-0.2-3.1c-0.2-1-0.4-2-0.8-2.9c-0.4-0.9-0.9-1.9-1.4-2.7c-0.6-0.8-1.3-1.6-2-2.3
			c-0.8-0.7-1.6-1.4-2.3-2.1c-0.7-0.8-1.5-1.6-1.9-2.6c-0.5-1-0.5-2.2-0.1-3.3c0.4-1.1,1.1-1.9,1.8-2.7c1.3-1.6,2-3.6,2-5.6
			c0-1-0.1-2-0.5-2.9c-0.4-0.9-1-1.8-1.7-2.4c-0.8-0.6-1.7-1.1-2.6-1.2c-1-0.1-2,0.1-2.8,0.7c-0.8,0.6-1.4,1.4-1.8,2.3
			c-0.4,0.9-0.4,1.9-0.2,2.9l-0.6,0.1c0.1-1.9,0.6-3.9,1.9-5.4c1.2-1.5,3.7-2.1,5.5-1.1c0.9,0.5,1.5,1.4,1.8,2.4
			c0.3,1,0.2,2,0.2,2.9c-0.2,1.9-0.5,3.8,0.3,5.5c0.7,1.6,2.3,2.8,4.1,3.3c1.8,0.4,3.7,0.2,5.2-0.8c1.5-1,2.6-2.6,3.3-4.3
			c0.7-1.7,1.1-3.6,1.2-5.4c0-0.9,0-1.9-0.1-2.8c-0.1-0.9-0.2-1.9-0.5-2.8c-0.4-1.8-1.1-3.6-1.9-5.3c-0.8-1.7-1.9-3.2-3.1-4.6
			c-1.2-1.4-2.6-2.7-4.2-3.7c-1.5-1.1-3.2-1.9-5-2.5c-0.4-0.2-0.9-0.3-1.3-0.4c-0.5-0.1-0.9-0.2-1.4-0.3c-0.9-0.1-1.9-0.3-2.8-0.1
			c-0.4,0.1-0.9,0.2-1.2,0.5c-0.2,0.1-0.3,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.8-0.3,1.8-0.3,2.7c0,0.9,0.1,1.9,0.1,2.9
			c-0.1,1-0.4,1.9-1,2.7c-0.6,0.8-1.5,1.2-2.5,1.5c-0.9,0.2-1.9,0.2-2.9,0.1c-0.9-0.2-1.9-0.5-2.7-1l-0.6-0.3l-0.6-0.4
			c-0.4-0.3-0.8-0.6-1.1-0.9c-2.9-2.6-4.6-6.2-5.2-9.9c-0.3-1.9-0.3-3.8,0.1-5.7c0.4-1.9,1.2-3.6,1.6-5.4c0.2-0.9,0.4-1.8,0.4-2.8
			c0-0.9-0.3-1.8-0.8-2.6c-0.6-0.7-1.4-1.2-2.3-1.3c-0.9-0.1-1.8,0.1-2.7,0.5c-1.7,0.7-3,2.2-4,3.8c-1,1.6-1.6,3.4-1.9,5.2
			c-0.7,3.7-0.1,7.6,1.5,11c1.6,3.4,4.1,6.4,7,8.7c1.4,1.2,2.8,2.7,3.1,4.6c0.1,1-0.1,1.9-0.6,2.8c-0.2,0.4-0.6,0.8-1,1
			c-0.4,0.3-0.9,0.3-1.4,0.2c0,0,0,0,0-0.1c0,0,0,0,0,0c0.7-0.4,1.5-0.6,2.3-0.6c0.8,0,1.6,0.3,2.3,0.6c1.4,0.7,2.6,2,3.3,3.4
			c0.7,1.4,1.1,3,1.3,4.6c0.2,1.6,0.3,3.2,0.3,4.8c0,1.6,0.1,3.2,0.3,4.8c0.2,1.6,0.6,3.1,1.4,4.5c0.7,1.4,1.8,2.6,3.1,3.5
			c1.3,0.9,2.7,1.6,4.2,2.3c-1.4-0.7-2.9-1.4-4.2-2.3c-1.3-0.9-2.4-2.1-3.1-3.5c-0.7-1.4-1.1-3-1.4-4.5c-0.2-1.6-0.3-3.2-0.4-4.8
			c-0.1-1.6-0.1-3.2-0.3-4.8c-0.2-1.6-0.6-3.1-1.3-4.5c-0.7-1.4-1.9-2.6-3.3-3.3c-0.7-0.4-1.5-0.6-2.3-0.6c-0.8,0-1.6,0.2-2.2,0.6
			l0-0.1c0.4,0.1,0.9,0,1.3-0.2c0.4-0.2,0.7-0.6,0.9-1c0.5-0.8,0.7-1.8,0.6-2.7c-0.1-0.9-0.6-1.8-1.1-2.5c-0.6-0.8-1.3-1.4-2-2
			c-2.9-2.4-5.5-5.3-7.1-8.7c-1.6-3.4-2.3-7.3-1.6-11.1c0.3-1.9,1-3.7,2-5.3c1-1.6,2.3-3.1,4.1-3.9c0.9-0.4,1.8-0.6,2.8-0.5
			c1,0.1,1.9,0.6,2.5,1.4c0.6,0.8,0.9,1.8,0.9,2.7c0,1-0.2,1.9-0.4,2.8c-0.5,1.8-1.2,3.6-1.6,5.4c-0.4,1.8-0.4,3.7-0.1,5.6
			c0.6,3.7,2.3,7.2,5.1,9.7c0.4,0.3,0.7,0.6,1.1,0.9l0.6,0.4l0.6,0.3c0.8,0.5,1.7,0.8,2.6,0.9c1.8,0.3,3.9-0.1,5-1.5
			c0.6-0.7,0.9-1.6,0.9-2.5c0.1-0.9,0-1.9-0.1-2.8c0-0.9-0.1-1.9,0.3-2.9c0.1-0.2,0.2-0.5,0.4-0.7c0.2-0.2,0.3-0.4,0.5-0.5
			c0.4-0.3,0.9-0.5,1.4-0.6c1-0.2,1.9,0,2.9,0.1c0.5,0,0.9,0.2,1.4,0.3c0.5,0.1,0.9,0.2,1.4,0.4c1.8,0.5,3.5,1.4,5.1,2.5
			c1.6,1.1,3,2.3,4.2,3.8c1.2,1.4,2.3,3,3.2,4.7c0.9,1.7,1.6,3.5,2,5.4c0.3,0.9,0.3,1.9,0.5,2.8c0.1,1,0.1,1.9,0.1,2.9
			c-0.1,1.9-0.5,3.8-1.2,5.6c-0.7,1.8-1.9,3.4-3.5,4.5c-3.3,2.2-8.4,1-10.1-2.7l-0.3-0.7c-0.1-0.2-0.1-0.5-0.2-0.7
			c-0.1-0.5-0.1-1-0.1-1.5c0-1,0.1-1.9,0.2-2.8c0.1-0.9,0.1-1.9-0.2-2.7c-0.2-0.8-0.8-1.6-1.5-2c-0.8-0.4-1.7-0.6-2.5-0.4
			c-0.9,0.2-1.6,0.7-2.2,1.3c-1.2,1.4-1.7,3.2-1.8,5.1c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.3-1.1-0.2-2.2,0.2-3.3
			c0.4-1,1.1-1.9,2-2.6c0.9-0.7,2.1-1,3.2-0.8c1.1,0.1,2.1,0.7,3,1.4c0.8,0.7,1.5,1.6,1.9,2.6c0.4,1,0.6,2.1,0.6,3.2
			c0,2.2-0.8,4.3-2.1,6c-0.6,0.8-1.3,1.6-1.7,2.5c-0.3,0.9-0.3,1.9,0.1,2.8c0.4,0.9,1.1,1.7,1.8,2.4c0.7,0.7,1.5,1.4,2.3,2.1
			c0.8,0.7,1.5,1.5,2.1,2.4c0.6,0.9,1.1,1.8,1.5,2.8c0.4,1,0.7,2,0.9,3.1c0.2,1,0.2,2.2,0.2,3.2l0,0c0,0.2-0.2,0.3-0.4,0.3
			S207.6,253.7,207.6,253.5L207.6,253.5z"
              />
            </g>
            <g>
              <path
                id="XMLID_23_"
                d="M17.1,249.9l-0.8,0l-0.8-0.1c-0.5-0.1-1.1-0.2-1.6-0.3c-1-0.3-2-0.7-2.9-1.3c-0.9-0.6-1.7-1.3-2.4-2.1
			c-0.7-0.8-1.2-1.8-1.6-2.8c-0.2-0.5-0.3-1-0.4-1.6c-0.1-0.5-0.1-1.1,0-1.6c0-0.5,0.1-1.1,0.3-1.6l0.2-0.8l0.3-0.7
			c0.2-0.5,0.5-1,0.8-1.4c0.3-0.4,0.6-0.9,1-1.3c0.4-0.4,0.8-0.7,1.2-1.1l0.7-0.5l0.7-0.4c0.1-0.1,0.3,0,0.4,0.1l0,0l0.4,0.6
			c0.2,0.2,0.3,0.4,0.5,0.6c0.3,0.4,0.7,0.7,1.1,1c0.8,0.6,1.8,1,2.8,1.3c2,0.7,4.1,1,6.1,1.5c0.5,0.1,1,0.2,1.6,0.4
			c0.5,0.2,1,0.4,1.5,0.6c0.5,0.2,1,0.5,1.4,0.7c0.5,0.3,0.9,0.6,1.3,0.9c0.4,0.3,0.8,0.7,1.2,1.1c0.4,0.4,0.7,0.9,0.9,1.4
			c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.1,0.6,0.1,0.8c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.2-0.2,0.2-0.4,0.2
			c-0.1,0-0.2-0.2-0.2-0.3l0,0c0.2-1.6-0.6-3.1-1.8-4.1c-1.2-1.1-2.6-1.9-4.1-2.5c-3-1.2-6.4-1.8-9.1-3.8c-0.7-0.5-1.3-1.1-1.8-1.8
			c-0.5-0.7-0.8-1.6-0.9-2.4c-0.2-1.7,0.2-3.5,1.2-4.8c0.5-0.7,1.1-1.3,1.8-1.8c0.7-0.5,1.5-0.8,2.3-1c1.6-0.4,3.3-0.6,4.4-1.7
			c1.2-1.1,1.2-2.9,0.6-4.4c-0.5-1.5-1.7-2.7-2.8-3.9c-1.1-1.2-2.4-2.3-3.2-3.8c-0.4-0.7-0.7-1.6-0.8-2.4c-0.1-0.8-0.1-1.7,0-2.5
			c0.6-3.3,2.8-6.2,5.5-8c2.8-1.9,5.9-3,9.1-3.8c3.2-0.8,6.4-1.8,9.7-2c1.7-0.1,3.3,0,4.9,0.6c0.8,0.3,1.5,0.7,2.2,1.2
			c0.6,0.5,1.2,1.1,1.7,1.8c0.9,1.4,1.3,3.1,1.4,4.7c0.1,1.7,0.1,3.3,0,4.9c-0.1,1.6-0.1,3.3,0,4.9c0.1,1.6,0.5,3.2,1.3,4.6
			c0.8,1.4,2.1,2.5,3.6,3.1c1.5,0.6,3.2,0.7,4.8,0.4c1.6-0.3,3.1-1,4.3-2.1c0.6-0.6,1-1.2,1.4-2c0.3-0.7,0.6-1.5,0.9-2.3
			c0.7-1.5,2-2.7,3.6-3.3c1.6-0.6,3.3-0.7,4.9-0.1c1.6,0.5,2.9,1.8,3.5,3.4c0.6,1.5,0.6,3.4-0.2,4.8c0,0-0.1,0.1-0.1,0
			c0,0,0,0,0-0.1c0.1-1.3-0.2-2.6-0.9-3.7c-0.7-1.1-1.7-2.1-2.8-2.7c-1.2-0.6-2.5-1-3.8-0.9c-1.3,0.1-2.6,0.5-3.7,1.2
			c-1.1,0.7-2.1,1.6-2.7,2.8c-0.6,1.2-0.8,2.5-0.6,3.8c0.2,1.3,0.7,2.6,1.2,3.7c0.6,1.2,1.2,2.3,1.8,3.5c1.2,2.3,2.2,4.9,2.1,7.6
			c-0.1,2.6-1.7,5.2-4.1,6.4c-1.2,0.5-2.6,0.7-3.9,0.3c-1.3-0.3-2.4-1.1-3.3-2.1c-0.9-1-1.6-2.1-2-3.4c-0.4-1.3-0.4-2.6-0.1-3.9
			c-0.4,1.3-0.3,2.7,0.1,3.9c0.4,1.3,1.1,2.4,2,3.4c0.9,1,2,1.7,3.3,2.1c1.3,0.3,2.7,0.2,3.9-0.4c2.4-1.1,4-3.7,4.1-6.3
			c0.1-2.7-0.9-5.2-2.1-7.5c-0.6-1.2-1.3-2.3-1.9-3.5c-0.6-1.2-1.1-2.4-1.3-3.8c-0.2-1.3,0-2.7,0.6-3.9c0.6-1.2,1.6-2.2,2.7-2.9
			c1.1-0.7,2.4-1.1,3.8-1.2c1.3-0.1,2.7,0.2,3.9,0.9c1.2,0.6,2.2,1.6,2.9,2.7c0.7,1.1,1.1,2.5,0.9,3.8l-0.2,0
			c0.8-1.4,0.8-3.2,0.2-4.7c-0.6-1.5-1.9-2.7-3.4-3.3c-1.5-0.5-3.2-0.5-4.8,0.1c-1.5,0.6-2.8,1.7-3.5,3.2c-0.3,0.7-0.6,1.5-0.9,2.3
			c-0.3,0.8-0.8,1.5-1.4,2c-1.2,1.2-2.8,1.9-4.4,2.2c-1.6,0.3-3.3,0.2-4.9-0.4c-1.6-0.6-2.9-1.7-3.8-3.2c-0.9-1.4-1.2-3.1-1.4-4.8
			c-0.1-1.6-0.1-3.3,0-4.9c0-1.6,0.1-3.3,0-4.9c-0.2-1.6-0.5-3.2-1.4-4.6c-0.4-0.7-1-1.3-1.6-1.8c-0.6-0.5-1.3-0.9-2.1-1.1
			c-1.5-0.5-3.2-0.7-4.8-0.6c-3.3,0.2-6.4,1.1-9.6,2c-3.1,0.9-6.3,2-9,3.8c-2.7,1.8-4.8,4.6-5.3,7.8c-0.1,0.8-0.1,1.6,0,2.4
			c0.1,0.8,0.4,1.5,0.7,2.3c0.8,1.4,2,2.6,3.1,3.7c0.6,0.6,1.2,1.2,1.7,1.8c0.5,0.7,0.9,1.4,1.2,2.2c0.3,0.8,0.5,1.7,0.4,2.5
			c0,0.4-0.2,0.9-0.3,1.3c-0.2,0.4-0.5,0.8-0.8,1.1c-1.3,1.3-3.1,1.4-4.6,1.8c-0.8,0.2-1.5,0.5-2.2,0.9c-0.7,0.4-1.2,1-1.7,1.6
			c-0.9,1.3-1.3,2.9-1.1,4.5c0.2,1.6,1.2,2.9,2.5,3.9c2.6,1.9,5.9,2.5,9,3.7c1.5,0.6,3,1.5,4.3,2.6c0.6,0.6,1.2,1.3,1.5,2
			c0.4,0.8,0.5,1.7,0.4,2.6l-0.6-0.1c0.1-0.2,0.2-0.5,0.2-0.7c0-0.2,0.1-0.5,0-0.7c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.1-0.5-0.2-0.7
			c-0.2-0.5-0.5-0.9-0.8-1.3c-0.3-0.4-0.7-0.7-1.1-1.1c-0.4-0.3-0.8-0.6-1.3-0.9c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.2-1-0.4-1.4-0.6
			c-0.5-0.2-1-0.3-1.5-0.4c-2.1-0.4-4.2-0.8-6.2-1.5c-1-0.3-2-0.8-2.9-1.4c-0.5-0.3-0.9-0.7-1.3-1.1c-0.2-0.2-0.3-0.4-0.5-0.7
			c-0.2-0.2-0.3-0.5-0.4-0.7L12,233l-0.7,0.4l-0.6,0.4c-0.4,0.3-0.8,0.6-1.1,1c-0.3,0.4-0.7,0.8-0.9,1.2c-0.3,0.4-0.5,0.9-0.7,1.3
			L7.7,238l-0.2,0.7c-0.1,0.5-0.2,1-0.2,1.5c0,0.5,0,1,0,1.5c0.1,0.5,0.2,1,0.3,1.4c0.3,0.9,0.8,1.8,1.5,2.6c0.6,0.8,1.4,1.4,2.3,2
			c0.8,0.5,1.8,0.9,2.7,1.2c0.5,0.1,1,0.2,1.5,0.3l0.7,0.1l0.8,0c0.2,0,0.4,0.2,0.4,0.4S17.3,249.9,17.1,249.9L17.1,249.9z"
              />
            </g>
            <g>
              <path
                id="XMLID_21_"
                d="M167.4,187.9l0.1-0.7l0-0.7c0-0.5-0.1-1-0.2-1.4c-0.2-1-0.5-1.9-1-2.7c-0.9-1.7-2.3-3.2-4-4.1
			c-0.4-0.3-0.9-0.4-1.3-0.6c-0.5-0.2-0.9-0.3-1.4-0.4c-0.9-0.2-1.9-0.4-2.9-0.4c-1,0-2,0.1-2.9,0.3c-0.5,0.1-0.9,0.3-1.4,0.4
			c-0.5,0.2-0.9,0.4-1.3,0.6l0,0c-0.2,0.1-0.3,0-0.4-0.1c0-0.1,0-0.2,0-0.3c0.4-1-0.1-2.1-0.9-2.8c-0.8-0.7-1.9-1.2-3.1-1.3
			c-1.1-0.1-2.3,0.2-3.1,0.8c-0.4,0.3-0.7,0.8-1.1,1.3c-0.4,0.5-0.8,0.9-1.2,1.4c-1.9,1.6-4.4,2.3-6.8,2.2c-2.4-0.2-4.8-1.5-6.2-3.5
			c-0.7-1-1.2-2.2-1.4-3.4c-0.1-0.6-0.1-1.2,0-1.9c0.1-0.6,0.2-1.2,0.4-1.8c0.9-2.3,2.7-4.1,4.6-5.5c1.9-1.4,4-2.5,6.2-3.6
			c2.1-1,4.3-2,6.6-2.8c2.2-0.8,4.5-1.5,6.8-2.1c2.3-0.7,4.5-1.4,6.6-2.4c2.1-1.1,4-2.6,4.8-4.8c0.9-2.2,0.5-4.7-0.7-6.7
			c-1.1-2-3-3.7-5-4.8c-1-0.6-2.1-0.9-3.3-1.3c-1.1-0.4-2.3-0.9-3.1-1.8c-1.7-1.9-1.8-4.7-0.8-6.9c1-2.2,2.6-4,4.3-5.7
			c0.1-0.1,0.1-0.1,0.2,0c0,0,0.1,0.1,0,0.2c-0.9,1.5-0.8,3.4-0.1,5c0.7,1.6,2.1,2.8,3.7,3.5c1.6,0.7,3.4,0.9,5.1,0.7
			c0.9-0.1,1.7-0.4,2.6-0.3c0.9,0,1.8,0.6,2.3,1.4c0.5,0.8,0.8,1.6,1.1,2.4c0.3,0.8,0.5,1.7,0.7,2.6c0.4,1.7,0.6,3.5,0.6,5.3
			c0,3.5-0.7,7.1-1.9,10.4c-1.2,3.3-3.1,6.4-5.6,8.9c-1.3,1.2-2.7,2.3-4.3,3.1c-1.6,0.8-3.3,1.3-5,1.6c-3.5,0.6-7,0.4-10.5,0.7
			c-1.7,0.2-3.5,0.3-5.1,1.2c-0.8,0.4-1.4,1.1-1.8,1.9c-0.4,0.8-0.4,1.8,0.1,2.5l0,0c0.7-0.1,1.5-0.2,2.3-0.2c0.8,0,1.5,0.1,2.2,0.4
			c0.7,0.3,1.2,0.9,1.5,1.6c0.1,0.4,0.2,0.7,0.1,1.1c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.5c0.1-0.1,0.3-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.4,0-0.8-0.1-1.1c-0.3-0.7-0.8-1.3-1.5-1.6c-0.7-0.3-1.5-0.4-2.2-0.4c-0.8,0-1.5,0.1-2.3,0.3
			c0,0,0,0,0,0c-0.5-0.8-0.4-1.8-0.1-2.5c0.4-0.8,1-1.5,1.8-1.9c1.6-0.9,3.4-1,5.1-1.2c3.5-0.3,7.1-0.1,10.5-0.7
			c1.7-0.3,3.4-0.8,5-1.6c1.6-0.8,3-1.8,4.3-3c2.5-2.4,4.4-5.6,5.6-8.8c1.2-3.3,1.9-6.8,1.9-10.3c0-1.8-0.2-3.5-0.6-5.2
			c-0.2-0.9-0.4-1.7-0.7-2.5c-0.3-0.8-0.6-1.7-1.1-2.4c-0.5-0.7-1.2-1.2-2.1-1.3c-0.9,0-1.7,0.2-2.6,0.3c-1.8,0.2-3.6,0-5.2-0.7
			c-0.8-0.3-1.6-0.8-2.3-1.4c-0.7-0.6-1.2-1.3-1.6-2.2c-0.8-1.6-0.8-3.6,0.1-5.2l0.2,0.2c-1.6,1.7-3.3,3.5-4.2,5.6
			c-0.9,2.1-0.8,4.8,0.8,6.5c1.6,1.7,4.2,1.8,6.3,3c2.1,1.1,4,2.8,5.2,4.9c0.6,1,1,2.2,1.2,3.4c0,0.3,0.1,0.6,0.1,0.9
			c0,0.3,0,0.6-0.1,0.9l-0.2,0.9l-0.3,0.8c-0.9,2.3-2.9,3.9-5,5c-2.1,1.1-4.4,1.8-6.7,2.5c-2.3,0.7-4.6,1.3-6.8,2.1
			c-2.2,0.8-4.4,1.7-6.5,2.8c-2.1,1-4.2,2.2-6.1,3.6c-1.9,1.4-3.6,3.1-4.4,5.3c-0.2,0.5-0.3,1.1-0.4,1.7c-0.1,0.5-0.1,1.1,0,1.7
			c0.2,1.1,0.6,2.2,1.3,3.1c1.3,1.9,3.5,3.1,5.8,3.2c2.3,0.2,4.7-0.6,6.4-2c0.4-0.4,0.8-0.8,1.1-1.3c0.3-0.5,0.7-1,1.2-1.4
			c1-0.8,2.3-1.1,3.6-1c1.2,0.1,2.4,0.6,3.4,1.4c0.5,0.4,0.9,0.9,1.1,1.6c0.2,0.6,0.2,1.3,0,2l-0.5-0.4c0.5-0.2,0.9-0.5,1.4-0.7
			c0.5-0.2,1-0.3,1.5-0.5c1-0.2,2-0.4,3.1-0.4c1,0,2.1,0.1,3.1,0.4c0.5,0.1,1,0.3,1.5,0.5c0.5,0.2,1,0.4,1.4,0.7
			c1.8,1,3.3,2.5,4.3,4.4c0.5,0.9,0.9,1.9,1.1,2.9c0.1,0.5,0.2,1,0.2,1.6c0,0.3,0,0.5,0,0.8l-0.1,0.8c0,0.2-0.2,0.3-0.4,0.3
			S167.4,188.1,167.4,187.9L167.4,187.9z"
              />
            </g>
            <g>
              <path
                id="XMLID_19_"
                d="M54.3,205.2c3.2,2.3,7.7,2.5,11.1,0.6c1.7-1,3-2.5,3.7-4.4c0.7-1.8,0.7-3.9,0.1-5.7
			c-0.3-0.9-0.8-1.8-1.4-2.5c-0.6-0.7-1.4-1.4-2.1-2.1c-1.5-1.3-3-2.8-4-4.7c-2-3.6-2.5-8-1.4-11.9c0.6-2,1.5-3.8,2.8-5.4
			c1.3-1.6,3-2.9,4.7-3.8c3.6-2,7.8-2.7,11.9-2.2c2,0.3,4,0.9,5.8,1.8c1.8,0.9,3.6,1.9,5.5,2.2c0.2,0,0.5,0.1,0.7,0.1l0.8,0
			c0.3,0,0.5-0.1,0.7-0.1c0.2,0,0.5-0.1,0.7-0.1c0.9-0.2,1.8-0.7,2.6-1.3c1.6-1.2,2.7-2.9,3.5-4.7c0.7-1.8,1-3.8,0.8-5.8
			c-0.3-1.9-1.1-3.9-2.6-5.1c-0.7-0.6-1.7-1-2.6-1.1c-1-0.1-1.9,0-2.9,0.3c-1.9,0.5-3.8,1.4-5.9,1.5c-2.1,0.1-4.1-0.7-5.8-1.9
			c-1.7-1.2-3.1-2.7-4.2-4.4C74.4,141,73,137,72.7,133c-0.4-4,0.3-8.2,2-11.9c1.7-3.7,4.4-6.9,7.7-9.3c3.2-2.4,7-4.2,10.9-5.1
			c1-0.2,2-0.4,3-0.5c1,0,2,0.1,3,0.3c1,0.3,2,0.6,2.7,1.3c0.4,0.3,0.7,0.8,0.9,1.3c0.2,0.5,0.2,1,0.1,1.6c0,0,0,0.1-0.1,0.1l0,0
			c-2,0.2-4,0.6-5.8,1.4c-1.8,0.8-3.5,1.9-5,3.2c-1.5,1.3-2.8,2.8-3.8,4.6c-1,1.7-1.8,3.6-2.2,5.5c-0.4,2-0.4,4,0,6
			c0.3,1,0.5,1.9,1,2.8c0.4,0.9,1,1.7,1.6,2.5c1.3,1.5,3.2,2.5,5.2,2.4c2,0,3.8-1.2,5.3-2.5c1.5-1.4,2.7-2.9,4.1-4.4
			c1.4-1.5,2.9-2.9,4.7-3.8c0.4-0.3,0.9-0.4,1.4-0.5c0.5-0.2,1-0.2,1.5-0.3c1-0.1,2,0,3,0.2c2,0.4,3.8,1.3,5.4,2.6
			c1.6,1.2,2.9,2.8,3.8,4.7c0.9,1.8,1.2,3.9,1,5.9c-0.2,2-1,3.9-2.1,5.6c-1.1,1.7-2.7,3-4.4,4c1.8-1,3.3-2.4,4.4-4
			c1.1-1.6,1.9-3.6,2.1-5.6c0.2-2-0.2-4-1-5.9c-0.9-1.8-2.2-3.4-3.8-4.6c-1.6-1.2-3.4-2.1-5.4-2.5c-1-0.2-2-0.3-3-0.2
			c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-1,0.3-1.4,0.5c-1.8,0.9-3.3,2.3-4.6,3.7c-1.4,1.5-2.6,3.1-4.1,4.4c-1.5,1.3-3.3,2.6-5.3,2.6
			c-2,0.1-4-0.9-5.4-2.4c-0.7-0.8-1.2-1.6-1.7-2.5c-0.5-0.9-0.7-1.9-1-2.9c-0.4-2-0.4-4,0-6c0.4-2,1.2-3.9,2.2-5.6
			c1-1.8,2.4-3.3,3.9-4.6c1.5-1.4,3.3-2.4,5.1-3.3c1.9-0.8,3.9-1.2,5.9-1.4l-0.1,0.1c0.1-0.5,0.1-1-0.1-1.4
			c-0.2-0.4-0.5-0.9-0.8-1.2c-0.7-0.6-1.7-1-2.6-1.3c-1-0.2-2-0.4-3-0.3c-1,0-2,0.3-3,0.5c-3.9,0.9-7.6,2.6-10.8,5
			c-3.2,2.4-5.9,5.6-7.6,9.2c-1.7,3.6-2.4,7.7-2,11.7c0.4,4,1.8,7.9,4,11.2c1.1,1.7,2.5,3.1,4.1,4.3c1.6,1.1,3.6,1.9,5.5,1.8
			c2-0.1,3.8-1,5.8-1.5c1-0.3,2-0.4,3.1-0.3c1,0.1,2.1,0.5,2.9,1.2c1.6,1.3,2.5,3.4,2.8,5.4c0.3,2,0,4.2-0.8,6
			c-0.8,1.9-2,3.7-3.6,4.9c-1.6,1.3-3.8,1.9-5.9,1.5c-2.1-0.3-3.9-1.4-5.7-2.2c-1.8-0.9-3.7-1.5-5.7-1.7c-3.9-0.5-8,0.3-11.5,2.2
			c-1.7,1-3.3,2.2-4.6,3.7c-1.3,1.5-2.2,3.3-2.7,5.2c-1,3.8-0.6,8,1.4,11.5c1,1.7,2.3,3.2,3.8,4.5c0.8,0.7,1.5,1.4,2.2,2.1
			c0.7,0.8,1.2,1.7,1.6,2.7c0.7,2,0.6,4.2-0.1,6.2c-0.7,2-2.1,3.7-4,4.7c-3.6,2-8.4,1.9-11.8-0.6l0,0c-0.2-0.1-0.2-0.3-0.1-0.5
			C53.9,205.1,54.2,205,54.3,205.2L54.3,205.2z"
              />
            </g>
            <g>
              <path
                id="XMLID_17_"
                d="M121.1,159.4l-0.7,0.7l-0.6,0.8c-0.4,0.5-0.8,1.1-1.1,1.7c-0.7,1.2-1.1,2.5-1.4,3.8
			c-0.6,2.7-0.7,5.5-0.5,8.2c0.1,1.4,0.2,2.8,0.6,4.1c0.4,1.3,1,2.5,1.9,3.5c1,1,2.2,1.6,3.5,2c1.3,0.4,2.7,0.5,4.1,0.6
			c1.4,0.1,2.8,0,4.2,0l4.2-0.1l4.2-0.1c1.4,0,2.8-0.1,4.2-0.1c1.4,0,2.8,0.2,4.2,0.6c1.3,0.4,2.7,1.2,3.4,2.4
			c0.8,1.2,0.9,2.8,0.6,4.1c-0.3,1.4-0.9,2.7-1.5,3.9c0,0-0.1,0.1-0.1,0c0,0-0.1-0.1,0-0.1c0.2-0.9,0.4-1.8,0.7-2.7
			c0.3-0.9,0.7-1.7,1.2-2.5c0.2-0.4,0.5-0.8,0.9-1.1c0.3-0.3,0.7-0.6,1.1-0.9c0.8-0.5,1.7-0.8,2.7-0.8c1.9,0,3.8,0.9,4.8,2.5
			c1,1.6,1,3.7,0.2,5.4c0.8-1.7,0.7-3.8-0.3-5.3c-1-1.6-2.9-2.5-4.7-2.4c-0.9,0-1.8,0.3-2.6,0.8c-0.4,0.3-0.7,0.6-1.1,0.9
			c-0.3,0.3-0.6,0.7-0.8,1.1c-0.5,0.8-0.9,1.6-1.1,2.5c-0.3,0.9-0.5,1.8-0.7,2.7l-0.2-0.1c0.6-1.3,1.2-2.5,1.4-3.9
			c0.3-1.3,0.2-2.8-0.6-3.9c-0.7-1.2-2-1.9-3.3-2.3c-1.3-0.4-2.7-0.5-4.1-0.6c-1.4,0-2.8,0.1-4.2,0.1l-4.2,0.2l-4.2,0.2
			c-1.4,0.1-2.8,0.1-4.2,0.1c-1.4,0-2.8-0.2-4.2-0.6c-1.4-0.4-2.7-1.1-3.7-2.1c-1-1-1.7-2.3-2.1-3.7c-0.4-1.4-0.6-2.8-0.7-4.2
			c-0.2-2.8-0.2-5.6,0.5-8.4c0.3-1.4,0.8-2.7,1.5-4c0.3-0.6,0.7-1.2,1.2-1.8l0.7-0.8l0.7-0.7c0.1-0.1,0.4-0.1,0.5,0
			S121.2,159.3,121.1,159.4L121.1,159.4z"
              />
            </g>
            <g>
              <path
                id="XMLID_15_"
                d="M134.8,153.5c0,0,0.1-0.1,0.4-0.3c0.2-0.2,0.6-0.5,1-1c0.8-0.9,2-2.3,3-4.4c1-2,2-4.8,2-8
			c0-1.6-0.1-3.3-0.6-5c-0.2-0.9-0.6-1.7-1-2.6c-0.2-0.4-0.4-0.9-0.6-1.3c-0.2-0.4-0.5-0.8-0.8-1.2c-2.2-3.3-5.6-6.2-9.9-7.8
			c-1.1-0.4-2.2-0.7-3.3-1c-0.6-0.1-1.1-0.2-1.7-0.3c-0.6-0.1-1.2-0.2-1.8-0.3c-1.2-0.3-2.5-1-2.9-2.3c-0.2-0.6-0.2-1.3,0-2
			c0.2-0.6,0.4-1.2,0.8-1.8c0.7-1.1,1.7-2,2.8-2.6c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.2,1-0.3c0.7-0.1,1.3-0.2,2-0.2
			c2.6-0.1,5.2,0.4,7.8,0.4c2.6,0.1,5.3-0.5,7.4-2c2.2-1.5,3.8-3.7,4.9-6.1c1.2-2.4,1.9-5,2.2-7.6c0.4-2.6,0.5-5.3,0.4-7.9
			c-0.1-2.6-0.5-5.2-1.1-7.7c-0.6-2.5-1.5-4.9-2.4-7.2c-1.9-4.6-4.3-8.9-6.4-13c-2.1-4.1-4-8-5.3-11.8c-0.6-1.9-1.1-3.8-1.5-5.6
			c-0.3-1.8-0.6-3.5-0.6-5.2c-0.2-3.3,0.2-6.1,0.9-8.4c0.6-2.3,1.5-3.9,2.1-5c0.7-1,1.1-1.6,1.1-1.6s-0.4,0.6-1.1,1.6
			c-0.6,1.1-1.5,2.7-2.1,5c-0.6,2.3-1,5.1-0.8,8.4c0.1,1.6,0.3,3.3,0.7,5.1c0.4,1.8,0.9,3.6,1.5,5.5c1.3,3.8,3.2,7.7,5.4,11.8
			c2.1,4.1,4.5,8.3,6.5,13c1,2.3,1.8,4.7,2.5,7.2c0.6,2.5,1,5.1,1.2,7.8c0.1,2.6,0,5.3-0.4,8c-0.4,2.6-1.1,5.3-2.2,7.7
			c-1.2,2.4-2.8,4.7-5,6.2c-2.2,1.6-5,2.2-7.6,2.1c-2.7-0.1-5.2-0.5-7.8-0.4c-1.3,0.1-2.5,0.3-3.6,0.9c-1.1,0.6-2,1.5-2.7,2.5
			c-0.3,0.5-0.6,1.1-0.7,1.6c-0.1,0.6-0.2,1.2,0,1.7c0.2,0.5,0.5,1,1,1.3c0.4,0.4,1,0.5,1.6,0.7c0.6,0.1,1.2,0.2,1.8,0.3
			c0.6,0.1,1.2,0.2,1.8,0.3c1.2,0.3,2.3,0.6,3.4,1c4.4,1.7,7.9,4.6,10.2,8c2.3,3.4,3.2,7.2,3.1,10.5c-0.1,3.3-1,6.1-2.1,8.3
			c-1.1,2.1-2.3,3.6-3.1,4.5c-0.4,0.5-0.8,0.8-1,1c-0.2,0.2-0.4,0.3-0.4,0.3c-0.1,0.1-0.4,0.1-0.5,0S134.7,153.6,134.8,153.5
			L134.8,153.5z"
              />
            </g>
            <g>
              <path
                id="XMLID_13_"
                d="M102.6,108.3c0,0,0.1,0,0.3,0.1c0.2,0.1,0.6,0.2,1,0.2c0.9,0.2,2.2,0.3,3.8,0c1.6-0.3,3.6-1,5.5-2.4
			c1.9-1.3,3.6-3.5,4.8-6.1c1.2-2.6,2-5.7,2.4-9.1c0.4-3.3,0.6-6.9,0.2-10.5c-0.4-3.6-1.4-7.3-3.3-10.7c-1.9-3.3-4.4-6.4-6.8-9.5
			c-2.4-3.1-4.7-6.3-6-10c-1.3-3.6-1.8-7.5-1.8-11.2c0-3.7,0.5-7.2,1-10.6c0.5-3.3,1-6.5,1.4-9.4c0.2-1.5,0.4-2.9,0.5-4.2
			c0.1-0.7,0.1-1.3,0.2-1.9c0-0.6,0-1.2,0.1-1.8c0-0.6,0-1.2,0-1.7c0-0.6-0.1-1.1-0.1-1.6c0-0.5-0.1-1-0.1-1.5
			c0-0.5-0.1-0.9-0.2-1.3c-0.1-0.8-0.2-1.6-0.4-2.3c-0.2-0.6-0.3-1.2-0.4-1.6c-0.2-0.9-0.4-1.4-0.4-1.4s0.1,0.5,0.4,1.4
			c0.1,0.4,0.3,1,0.4,1.6c0.1,0.6,0.2,1.4,0.4,2.3c0.1,0.4,0.2,0.9,0.2,1.3c0,0.5,0.1,1,0.1,1.5c0,0.5,0.1,1.1,0.1,1.6
			c0,0.6,0,1.1,0,1.7c0,0.6,0,1.2,0,1.8c0,0.6-0.1,1.3-0.1,2c-0.1,1.3-0.3,2.7-0.4,4.2c-0.4,2.9-0.9,6.1-1.3,9.4
			c-0.4,3.3-0.9,6.9-0.9,10.5c0,3.7,0.5,7.5,1.8,11.1c1.2,3.6,3.6,6.8,6,9.9c2.4,3.1,5,6.1,6.9,9.5c1.9,3.4,3,7.1,3.5,10.8
			c0.4,3.7,0.3,7.3-0.1,10.6c-0.4,3.4-1.1,6.5-2.4,9.2c-1.2,2.7-3,4.9-5,6.3c-2,1.5-4,2.2-5.7,2.5c-1.7,0.3-3.1,0.2-4.1,0
			c-0.5-0.1-0.8-0.2-1-0.2c-0.2-0.1-0.3-0.1-0.3-0.1c-0.2-0.1-0.3-0.2-0.2-0.4C102.2,108.4,102.4,108.3,102.6,108.3L102.6,108.3z"
              />
            </g>
            <g>
              <path
                id="XMLID_11_"
                d="M145.1,106.7c0,0,0.1,0,0.2-0.1c0.1-0.1,0.3-0.2,0.6-0.2c0.3-0.1,0.6-0.2,1-0.2c0.4,0,0.8,0,1.3,0.2
			c0.9,0.3,1.9,0.9,2.7,1.7c0.4,0.4,0.8,0.9,1.1,1.4c0.3,0.5,0.6,1.1,0.8,1.6c0.8,2.3,0.9,4.7,0.9,6.5c0,1.8-0.1,3-0.1,3s0-1.2,0-3
			c-0.1-1.8-0.3-4.2-1.1-6.4c-0.2-0.6-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-0.9-1.1-1.3c-0.8-0.8-1.7-1.3-2.5-1.5c-0.4-0.1-0.8-0.1-1.1-0.1
			c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1,0.1-0.2,0.1-0.2,0.1c-0.2,0.1-0.4,0-0.5-0.1
			C144.9,107,144.9,106.8,145.1,106.7C145.1,106.7,145.1,106.7,145.1,106.7z"
              />
            </g>
            <g>
              <path
                id="XMLID_9_"
                d="M73.3,207.1c0.7-0.7,1.3-1.4,1.8-2.2c0.5-0.8,1-1.6,1.4-2.5c0.4-0.9,0.7-1.8,0.9-2.7
			c0.2-0.9,0.4-1.9,0.4-2.8c0.1-0.9,0-1.9-0.1-2.8c-0.1-0.9-0.4-1.9-0.7-2.8c-0.3-0.9-0.7-1.8-1.2-2.6c-0.4-0.9-0.9-1.7-1.3-2.6
			c-0.4-0.9-0.8-1.8-1.1-2.7c-0.3-1-0.4-1.9-0.3-2.9c0.2-2,1.1-3.9,2.8-5.1c0.8-0.6,1.9-0.9,2.9-0.8c1,0,2,0.4,2.8,1.1
			c1.6,1.2,2.4,3.3,2.2,5.3c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1-1.2,0.2-2.4,0.9-3.4l0,0c0,0,0,0,0.1,0l1-0.4l1-0.3
			c0.7-0.1,1.3-0.2,2-0.3c1.4-0.1,2.7-0.1,4.1,0c1.4,0,2.7,0.1,4,0c1.3-0.1,2.7-0.3,3.9-0.9c0.6-0.3,1.2-0.6,1.7-1
			c0.5-0.4,1-0.9,1.4-1.4c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.3,0.3-0.6,0.5-0.9c0.3-0.6,0.5-1.3,0.6-1.9c0.3-1.3,0.4-2.7,0.3-4
			c0-0.7-0.1-1.3-0.2-2c-0.1-0.7-0.3-1.3-0.5-2c0.2,0.6,0.4,1.3,0.5,2c0.1,0.7,0.2,1.3,0.3,2c0.1,1.4,0,2.7-0.3,4
			c-0.2,0.7-0.4,1.3-0.6,1.9c-0.1,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.4,0.6-0.6,0.8c-0.4,0.5-0.9,1-1.4,1.5c-0.5,0.4-1.1,0.8-1.7,1
			c-1.2,0.6-2.6,0.8-4,0.9c-1.4,0.1-2.7,0.1-4.1,0.1c-1.4,0-2.7,0-4,0.1c-0.7,0.1-1.3,0.2-2,0.3l-1,0.3l-0.9,0.4l0.1-0.1
			c-0.6,1-0.9,2.1-0.8,3.3l-0.3,0c0.2-1.9-0.6-3.8-2.1-5c-0.7-0.6-1.6-0.9-2.6-1c-0.9,0-1.9,0.2-2.6,0.8c-1.5,1.1-2.4,2.9-2.5,4.8
			c-0.1,0.9,0.1,1.9,0.4,2.8c0.3,0.9,0.6,1.8,1.1,2.7c0.4,0.9,0.9,1.7,1.3,2.6c0.4,0.9,0.9,1.8,1.2,2.7c0.3,0.9,0.6,1.9,0.7,2.9
			c0.2,1,0.2,2,0.2,2.9c0,1-0.2,2-0.4,2.9c-0.2,1-0.5,1.9-0.9,2.8c-0.4,0.9-0.8,1.8-1.4,2.6c-0.5,0.8-1.2,1.6-1.8,2.3l0,0
			c-0.1,0.1-0.4,0.2-0.5,0C73.2,207.5,73.2,207.2,73.3,207.1L73.3,207.1z"
              />
            </g>
            <g>
              <path
                id="XMLID_7_"
                d="M64.4,218c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.3-0.5,0.6c-0.5,0.5-1.3,1.2-2.4,1.8
			c-1.1,0.6-2.6,1.1-4.4,1.2c-1.8,0.1-3.8-0.2-5.8-1.2c-1.9-1-3.7-2.7-4.9-4.9c-1.2-2.2-1.8-4.9-1.5-7.5c0.1-1.3,0.2-2.7,0.2-4
			c0-1.3-0.2-2.7-1-3.8c-0.4-0.5-0.9-1-1.5-1.3c-0.6-0.3-1.3-0.5-2-0.6c-1.4-0.2-2.8-0.1-4.2,0.2c-2.7,0.6-5.3,2.1-7,4.2
			c-0.9,1.1-1.6,2.3-1.9,3.6c-0.4,1.3-0.4,2.7,0.1,3.9c0.5,1.2,1.5,2.1,2.7,2.6c0.6,0.2,1.2,0.3,1.8,0.2c0.6-0.1,1.2-0.3,1.8-0.5
			c1.1-0.5,2.4-0.7,3.5-0.3c1.1,0.3,2.1,0.9,2.8,1.7c0.7,0.8,1.2,1.7,1.4,2.7c0.2,1,0,1.9-0.3,2.7c-0.6,1.6-1.8,2.7-2.8,3.4
			c-1,0.7-2,1-2.6,1.2c-0.3,0.1-0.6,0.1-0.7,0.2c-0.2,0-0.3,0-0.3,0s0.1,0,0.3,0c0.2,0,0.4-0.1,0.7-0.2c0.6-0.2,1.6-0.5,2.6-1.2
			c1-0.7,2.2-1.8,2.8-3.4c0.3-0.8,0.4-1.7,0.2-2.7c-0.2-0.9-0.7-1.9-1.4-2.6c-0.7-0.7-1.7-1.4-2.8-1.6c-1.1-0.3-2.3-0.1-3.4,0.4
			c-0.6,0.2-1.2,0.4-1.8,0.5c-0.6,0.1-1.3,0-1.9-0.2c-1.2-0.4-2.3-1.4-2.8-2.7c-0.5-1.3-0.5-2.7-0.1-4c0.4-1.3,1.1-2.6,1.9-3.7
			c1.8-2.2,4.4-3.7,7.2-4.4c1.4-0.3,2.8-0.4,4.3-0.3c0.7,0.1,1.4,0.3,2.1,0.6c0.7,0.3,1.2,0.8,1.7,1.4c0.9,1.2,1.1,2.7,1.1,4.1
			c0,1.4-0.1,2.7-0.2,4.1c-0.2,2.6,0.4,5.2,1.5,7.3c1.1,2.1,2.8,3.7,4.7,4.6c1.8,0.9,3.8,1.2,5.5,1.1c1.7-0.1,3.1-0.6,4.1-1.1
			c1.1-0.6,1.8-1.2,2.2-1.6c0.2-0.2,0.4-0.5,0.5-0.6c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.2,0.3-0.2,0.5-0.1S64.5,217.9,64.4,218L64.4,218
			z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "DiagramFire",
  components: {},
  mixins: [partComponent],
  props: ["show", "layers"],
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.staggerFrom(
        ".fire-img",
        0.5,
        {
          autoAlpha: 0,
          scale: 0.2,
          ease: Power1.easeOut,
        },
        0.1,
        0
      )
        .from(
          ".firebrown",
          0.7,
          {
            autoAlpha: 0,
            ease: Power1.easeIn,
          },
          0.2
        )
        .staggerFrom(
          "#flames path",
          1,
          {
            drawSVG: "0%",
            ease: Power1.easeOut,
          },
          0.05,
          "0.5"
        )
        .from(
          "#flames path",
          0.5,
          {
            fill: "rgba(0,0,0,0)",
            ease: Power1.easeIn,
          },
          "-=0.5"
        )
        .from(
          ".firered",
          0.7,
          {
            autoAlpha: 0,
            ease: Power1.easeIn,
          },
          "-=1.5"
        )
        .staggerFrom(
          ".fire-sparks",
          0.8,
          {
            autoAlpha: 0,
            scale: 0.4,
            ease: Power2.easeOut,
            y: "+=40",
            cycle: {
              rotation: [50, -50],
            },
          },
          0.1,
          "1.8"
        )
        .staggerFrom(
          ".fire-text",
          0.8,
          {
            autoAlpha: 0,
            ease: Power2.easeOut,
            y: "+=40",
          },
          0.1,
          "-=0.7"
        )
        .add("startSvg", "1.2")
        .staggerFrom(
          "#smoke path",
          2,
          {
            drawSVG: "0%",
            ease: Power2.easeOut,
          },
          0.1,
          "startSvg-=0.5"
        )
        .from(
          "#smoke path",
          0.5,
          {
            fill: "rgba(0,0,0,0)",
            ease: Power1.easeIn,
          },
          "-=0.5"
        )
        .from(
          ".fireblue",
          1.2,
          {
            autoAlpha: 0,
            ease: Power1.easeOut,
          },
          "-=1.7"
        );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.fire-diagram {
  max-width: 100%;
  width: 67.5em;
  height: 0;
  padding-top: 75.926%;
  position: relative;
  overflow: visible;
}

.fire-layer {
  left: 0;
  top: 0;
  transform-origin: bottom center;
}
.fire-layer img {
  width: 100%;
}
.colors {
  display: none;
}
.rocks {
  filter: drop-shadow(rgba(0, 0, 0, 0.7) 0rem 0.4rem 0.5rem);
}

.svg-layer {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
}
.svg-layer svg {
  position: absolute;
}
#smoke {
  width: 28.8%;
  left: 35%;
  top: 0;
}
#smoke path,
#flames path {
  stroke: black;
  stroke-width: 0.6;
  fill: rgba(0, 0, 0, 0, 0);
}

#flames {
  width: 36.4%;
  left: 29%;
  top: 33.2%;
}
</style>
